import { PrimeIcons } from "primereact/api";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedTable, TawreedTableColumnProps, TawreedTableProps } from "../../../../common/components/table";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { GlobalContext } from "../../../../context";
import { convertPartnerTypeToString, Partner, PartnerType } from "../../data";
import { PartnersService } from "../../domain";
import { Tag } from "primereact/tag";
import { tawreedTranslationService } from "../../../../common/translations";
import { CssUtils } from "../../../../helpers/css";

export interface PartnersListProps {
    type: PartnerType;
}


/**
 *
 * @param param0
 * @returns
 */
export const PartnersList: FC<PartnersListProps> = ({ type }) => {

    const history = useHistory();
    const service = new PartnersService();

    const { constants } = useContext(GlobalContext);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(`/partners/${convertPartnerTypeToString(type)}s/new`);
            resolve();
        });
    }

    const onForm = (sender?: Partner): Promise<void> => {
        return new Promise((resolve) => {
            history.push(`/partners/${convertPartnerTypeToString(type)}s/` + sender!.partnerId);
            resolve();
        });
    }
    const ProdcutStatus = [
        {
            value: true,
            label: tawreedTranslationService.translate("lbl_active")
        },
        {
            value: false,
            label: tawreedTranslationService.translate("lbl_not_active")
        }
    ];

    var columns : TawreedTableColumnProps[] = [
        {
            field: "partnerName",
            header: "lbl_partners_partner_name",
            sortable: true,
            filter: true,
            style: CssUtils.width('35%'),
        },
        {
            field: "startDate",
            header: "lbl_partners_start_date",
            sortable: true,
            filter: true,
            ...TawreedTableFilters.date.range('startDate', 'date'),
            style: CssUtils.width('35%'),
        },
        {
            field: "address.state",
            header: "lbl_partners_address_state_id",
            sortable: true,
            filter: true,
            filterField: "state",
            sortField: "state",
            style: CssUtils.width('15%'),
        },
        {
            field: "address.city",
            header: "lbl_partners_address_city_id",
            sortable: true,
            filter: true,
            filterField: "city",
            sortField: "city",
            style: CssUtils.width('15%'),
        },
        {
            field: "status",
            header: "lbl_partners_verified",
            filterField: "statusId",
            sortField:"statusId",
            filter: true,
            sortable: true,
            ...TawreedTableFilters.dropdown.dropdown(
                'status',
                constants.constants?.partnerStatus ?? [],
                'value',
                'label',
                'in',
                (_, valid) => <Tag rounded style={{ width: '5rem' }} severity={valid===3 ? 'success' : 'info'} value={tawreedTranslationService.translate(valid===2 ? 'lbl_new' : 'lbl_partners_verified')} />),
            style: CssUtils.width('10%'),
        },
        {
            field: "active",
            header: "lbl_partners_active",
            filter: true,
            sortable:true,
            ...TawreedTableFilters.dropdown.dropdown(
                'active',
                ProdcutStatus,
                'value',
                'label',
                'in',
                (_, valid) => <Tag rounded style={{ width: '5rem' }} severity={valid ? 'success' : 'danger'} value={tawreedTranslationService.translate(valid ? 'lbl_active' : 'lbl_not_active')} />),
             // ...TawreedTableFilters.boolean('active'),
            style: CssUtils.width('10%'),
        },
    ];
    
    if(type == PartnerType.Customer)
    {
        columns = [...columns,
            {
                field: "walletStatus",
                header: "lbl_wallet_status",
                filter: true,
                sortable:true,
                ...TawreedTableFilters.dropdown.dropdown('walletStatus', 
                constants.constants?.walletStatus ?? [], 'value', 'label', 'in',
                 (label: string, optionValue: string) => 
                 <Tag rounded severity={optionValue == "0" ? 'danger': 'success'} 
                    style={{ width: '6rem' }}
                    value={label} />),
                style: CssUtils.width('10%'),
            },]
    }
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'partnerId',
        name: 'tbl.partners.' + type,
        title: 'lbl_partners_' + type,
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(type, req),
        },
        columns: [
            ...columns,
            
        ],
        toolbar: {
            actions: [
                new TawreedAction(type === PartnerType.Store? TawreedActionName.StorePartnerCreate : TawreedActionName.CustomerPartnerCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
            ],
        },
        row: {
            onClick: new TawreedAction(type ===PartnerType.Store? TawreedActionName.StorePartnerDetails : TawreedActionName.CustomerPartnerDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'partnerName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'statusId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ]
                },
                'partnerType': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ],
                },
                'active': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ],
                },
                'walletStatus':{
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ],
                },
                'startDate': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        }
                    ],
                },
                'city': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        }
                    ],
                },
                'state': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        }
                    ],
                },
            },
            pre: [
                {
                    name: 'lbl_partners_new',
                    meta: {
                        'statusId': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: '2',
                                    matchMode: 'equals',
                                },
                            ]
                        },
                    },
                },
                {
                    name: 'lbl_partners_verified',
                    meta: {
                        'statusId': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: '3',
                                    matchMode: 'equals',
                                },
                            ]
                        },
                    },
                },
                {
                    name: 'lbl_partners_active',
                    meta: {
                        'active': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 'true',
                                    matchMode: 'equals',
                                },
                            ]
                        },
                    },
                },
                {
                    name: 'lbl_partners_not_active',
                    meta: {
                        'active': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 'false',
                                    matchMode: 'equals',
                                },
                            ]
                        },
                    },
                }
            ]
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'partnerName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
        },
    }
    return (<TawreedTable  {...tawreed} />);
}
