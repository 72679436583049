import { PrimeIcons } from "primereact/api";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { JObject } from "../../../../common/data/models";
import { buildSortParamsToString, PaginatedRequestParams } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { CssUtils } from "../../../../helpers/css";
import { tawreedHttpService } from "../../../../http";
import { TawreedConstants, TawreedMenu } from "../../../constants";
import { ReportDto, ReportResultDto, StatementReportItem, StatementReportParams } from "../../domain";
import { ReportMapper, StatementReportItemMapper } from "../mappers";
import { StatementReportParamsMapper } from "../mappers/statements-params";
import { StatementReportMapper } from "../mappers/statements-report";
import { ReportRepository } from "./report.repository";
import { AuthUtils, TawreedRoleName } from "../../../auth/domain";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../router";
import { PartnerType } from "../../../partners/data";
import { OutboundPaymentMethodService } from "../../../finance/domain";

export class StatementsReportRepository extends ReportRepository<StatementReportParams, StatementReportItem> {

    
        history = useHistory();
        private paymentMethods?: TawreedMenu;
        private readonly paymentMethodService = new OutboundPaymentMethodService();
    /**
     *
     * @param params
     */
    public async generate(params: StatementReportParams, scale: number, roleCode: TawreedRoleName,
         partnerType: PartnerType | undefined, constants: TawreedConstants | undefined): Promise<ReportDto<StatementReportItem>> {
        
            if (!this.paymentMethods)
                 {
                console.log("paymentMethods", this.paymentMethods);   
                this.paymentMethods = await this.paymentMethodService.getPaymentMethods();
            }
        const dataKey = 'transactionId';
        const getStoreColumnDependOnRole = () => {
            const arr = [];
            if (!partnerType || (partnerType && partnerType === PartnerType.Customer))
                arr.push(
            { field: "storeName", header: 'lbl_reports_statements_store_name'}
                )
            return arr;
        }

        const getCustomerColumnDependOnRole = () => {
            const arr = [];
            
            if (!partnerType || (partnerType && partnerType === PartnerType.Store))
                arr.push({ field: "customerName", header: 'lbl_reports_statements_customer_name'})
            return arr;
        }
        
        const columns = [
            { field: "transactionId", header: 'lbl_reports_statements_transactionid', style: CssUtils.width('5%') },
            { field: "txnDate", header: 'lbl_reports_statements_txndate', sortable: true,...TawreedTableFilters.date.range('txnDate', 'date'), style: CssUtils.width('10%'),alignHeader: AuthUtils.current().language ==="en"? "right" : "left"  },
            { field: "transactionTypeName", header: 'lbl_reports_statements_type', style: CssUtils.width('8%') },
            { field: "description", header: 'lbl_reports_statements_description', style: CssUtils.width('15%') },
            { field: "orderId", header: 'lbl_reports_statements_order_id', alignHeader: AuthUtils.current().language ==="en"? "left" : "right"  },
            { field: "total", header: 'lbl_reports_statements_order_total', ...TawreedTableFormatters.decimal('total', scale, CssUtils.width('10%')) },
            ...getStoreColumnDependOnRole(),
            ...getCustomerColumnDependOnRole(),
            { field: AuthUtils.current().language === "en" ? "paymentModeEn" : "paymentModeAr", header: 'lbl_reports_statements_payment_method_id',alignHeader: AuthUtils.current().language ==="en"? "left": "right"},
            { field: "creditAmount", header: 'lbl_reports_statements_creditamount', ...TawreedTableFormatters.decimal('creditAmount', scale, CssUtils.width('10%')), alignHeader: AuthUtils.current().language ==="en"? "right": "left"},
            { field: "debitAmount", header: 'lbl_reports_statements_debitamount', ...TawreedTableFormatters.decimal('debitAmount', scale, CssUtils.width('10%')), alignHeader: AuthUtils.current().language ==="en"? "right": "left"  },
            { field: "balance", header: 'lbl_reports_statements_balance', ...TawreedTableFormatters.decimal('balance', scale, CssUtils.width('10%')), alignHeader: AuthUtils.current().language ==="en"? "right": "left"  },
            
        ];
        const actions = (rowData: StatementReportItem) => {
            const res = new Array<TawreedAction>();
            // if (rowData.showPrintVoucher) {
            //     const onExportAs = (url?: string) => {
            //         return new Promise<void>((resolve, reject) => {
            //             try {
            //                 this.export(url!);
            //                 resolve();
            //             } catch (error) {
            //                 reject(error);
            //             }
            //         });
            //     }
            //     res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_pdf', PrimeIcons.FILE_PDF, () => onExportAs(rowData.voucherUrls?.pdfReportURI)));
            //     res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => onExportAs(rowData.voucherUrls?.xlsReportURI)));
            // }
            if (rowData.showPrintInvoice) {
                const onExportAs = (url: string | undefined) => {
                    return new Promise<void>((resolve, reject) => {
                        try {
                            this.export(url!);
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    });
                }
                res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_pdf', PrimeIcons.FILE_PDF, () => onExportAs(rowData.invoiceUrls?.pdfReportURI)));
                res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => onExportAs(rowData.invoiceUrls?.xlsReportURI)));
            }
            return res;
        };
        const onDetails = (sender?: StatementReportItem): Promise<void> => {
                    return new Promise((resolve, reject) => {
                        if (sender && sender.transactionId) {
                            this.history.push(Routes.TransactionsDetails.replace(':id', sender.transactionId.toString()));
                                    
                            resolve();
                        } else {
                            reject();
                        }
                    });
                }
        const filter = StatementReportParamsMapper.toFilter(params);
        const onSearch = (req: PaginatedRequestParams) => this.search(req, scale);
        const onclick = new TawreedAction(TawreedActionName.DeliveryPartnersDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false)
        return Promise.resolve(ReportMapper.toDto({ ...params, dataKey, columns, filter, onSearch, actions,onclick }));
    }

    public search(request: PaginatedRequestParams, scale: number): Promise<ReportResultDto<StatementReportItem>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = this.fromRequestParamsToJObject(request);

        return tawreedHttpService.post<JObject, JObject>(endpoints.REPORTS_STATEMENTS_FETCH, data, params, undefined, 'error')
            .then((res) => {
                console.log("res",res);
                if (!res || !res.data) {
                    return Promise.reject(null);
                }
                return StatementReportMapper.fromJson(res.data, scale, request.sort?.sortOrder!);
            });
    }
    /**
         * Get partner details by id
         * @param id
         */
        public async getDetails(id: number): Promise<StatementReportItem> {
            return tawreedHttpService.post<number, JObject>(endpoints.TRANSACTIONS_GET_DETAILS,
                 id, undefined, undefined, 'error').then(res => StatementReportItemMapper.fromJson(res.data!));
        }
    
}