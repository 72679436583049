import React from "react";
import { TawreedMessageDto } from "../../../domain";
import { Ripple } from "primereact/ripple";
import { PrimeIcons } from "primereact/api";
import { DateUtils } from "../../../../../helpers/date";
import { useHistory } from "react-router";
import { Routes } from "../../../../../router";

import './notification-item.scss';

export type NotificationItemProps = {
    /**
     *
     */
    notification: TawreedMessageDto;

    onMarkAsRead: () => Promise<void>;
}

export const NotificationItem: React.FC<NotificationItemProps> = (props) => {

    const history = useHistory();

    const onMarkAsRead = () => {
        if (props.onMarkAsRead) {
            props.onMarkAsRead().finally();
        }
    };

    const onClick = () => {
        if (props.notification.unread) {
            onMarkAsRead();
        }
        switch (props.notification.typeCode) {
            case "ORDER":
                history.push(Routes.SalesOrdersDetails.replace(':id', props.notification.recordId.toString()));
                break;
            case "RETURN_ORDER":
                history.push(Routes.SalesOrdersReturnedDetails.replace(':id', props.notification.recordId.toString()));
                break;
            case "CART":
                history.push(Routes.SalesCartsDetails.replace(':id', props.notification.recordId.toString()));
                break;
            case "PROMOTION":
                history.push(Routes.PostView.replace(':id', props.notification.recordId.toString()));
                break;
            // case "CUSTOMER":
            //     history.push(Routes.PartnersCustomersDetails.replace(':id', props.notification.recordId.toString()));
            //     break;
        }
    }

    return (
        <div className={`notification-item ${props.notification.unread ? 'unread' : 'read'} p-ripple col-12`} onClick={() => onClick()}>
            <Ripple />

            <div className="notification-item-inner flex align-items-center pt-3 pb-3 px-5 cursor-pointer hover:bg-gray-50">
                <div className="mr-3">
                    <i className={`icon ${PrimeIcons.BELL}`} />
                </div>
                <div className="">
                    <div className="font-bold">{props.notification.subject}</div>
                    {
                        props.notification.sendDate &&
                        <small className="text-blue-500">{DateUtils.format(props.notification.sendDate, "datetime")}</small>
                    }
                    <div className="">{props.notification.message}</div>
                </div>
            </div>
        </div>
    );
}
