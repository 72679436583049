import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildPaginatedRequest, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { DateUtils } from "../../../../helpers/date";
import { tawreedHttpService } from "../../../../http";
import { PostDto } from "../../domain/dtos";
import { PostMapper } from "../mapper";


export class PostsRepository {

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<PostDto>> {

        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.POST_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => PostMapper.toDto(PostMapper.fromJson(e))) ?? [] },
                };
            });
    }
    public searchAutoComplete(query: string, lang: string): Promise<PaginatedResponse<PostDto>> {
        let request: PaginatedRequestParams;
        if(lang === "en"){
            request = buildPaginatedRequest({
                sort: { sortOrder: 1, sortField: 'titleEn', multiSortMeta: undefined },
                filters: {
                    titleEn: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: query,
                                matchMode: "contains",
                            },
                        ],
                    },
                    endDate: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: DateUtils.now(),
                                matchMode: "dateAfter",
                            },
                        ],
                    },
                    startDate: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: DateUtils.now(),
                                matchMode: "dateBefore",
                            },
                        ],
                    },
                },
                size: 250,
            });
        }
        else{
            request = buildPaginatedRequest({
                sort: { sortOrder: 1, sortField: 'titleAr', multiSortMeta: undefined },
                filters: {
                    titleAr: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: query,
                                matchMode: "contains",
                            },
                        ],
                    },
                    endDate: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: DateUtils.now(),
                                matchMode: "dateAfter",
                            },
                        ],
                    },
                    startDate: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: DateUtils.now(),
                                matchMode: "dateBefore",
                            },
                        ],
                    },
                },
                size: 250,
            });
        }
       
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.POST_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => PostMapper.toDto(PostMapper.fromJson(e))) ?? [] },
                };
            });
    }
    
    /**
         * Create or update Post instance
         * @param form
         */
    // public async createOrUpdate(form: PostDto): Promise<PostDto> {
    //     await this.createOrUpdateLogo(form);
    //     await this.createOrUpdateFiles(form);
    //     let data = {
    //         partnerVO: PartnerMapper.toJson(PartnerMapper.fromDto({ ...form, partnerType: type })),
    //         userVO: form.userDto,
    //     }
    //     return tawreedHttpService.post<JObject, JObject>(endpoints.PARTNERS_CREATE_OR_SAVE, data)
    //         .then(res => {

    //             return PostMapper.toDto(PostMapper.fromJson(res.data!));
    //         });
    // }


    /**
        * Create or update partner instance
        * @param type
        * @param form
        */
    public async createOrUpdate(form: PostDto): Promise<PostDto> {
        // await this.createOrUpdateLogo(form);
        const data = PostMapper.toJson(PostMapper.fromDto(form));
        return tawreedHttpService.post<JObject, JObject>(endpoints.POST_CREATE_OR_SAVE, data)
            .then(res => {
                return PostMapper.fromJson(res.data!);
            });
    }

    public getDetails(id: number): Promise<PostDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.POST_DETAILS, id, undefined, undefined, 'error').then(res => {
            // return this.getProductImage(res.data!.image, res.data!.categoryId).then((image) => {
            //     return ProductMapper.toDto(ProductMapper.fromJson({ ...res.data!, image: image }))
            // })
            return PostMapper.toDto(PostMapper.fromJson({ ...res.data! }))
        });
    }

    /***
     * delete all
     * @param ids
     */
    public deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], number>(endpoints.DELETE_POSTS_ALL, ids).then(res => {
            return res.status === 200
        });
    }
}