import React from 'react'
import { TawreedReportGenerator } from './report-generator'
import { TawreedCalendar, TawreedDropdown, TawreedFormField } from "../../../../common/components/form";
import { PartnerAutoCompleteComponent } from "../../../partners";
import { DateUtils } from "../../../../helpers/date";
import { tawreedTranslationService } from '../../../../common/translations';
import { GlobalContext } from '../../../../context';
import { TabPanel, TabView } from 'primereact/tabview';
import { PartnerType } from '../../../partners/data';

/**
 *
 * @constructor
 */
export const ReportFinancialStatementsPerPartner: React.FC = () => {

    const { auth: { user } } = React.useContext(GlobalContext);
    const [activeIndex, setActiveIndex] = React.useState(0);

    const options = [
        {
            label: tawreedTranslationService.translate('lbl_rr_statements_op_debit'),
            value: 1,
        },
        {
            label: tawreedTranslationService.translate('lbl_rr_statements_op_credit'),
            value: 2,
        }
    ];

    return (
        <React.Fragment>
            {
                user?.roleCode === 'STORE_ADMIN' &&
                <TawreedReportGenerator partnerType={PartnerType.Store} name="statements-per-partner" title="lbl_reports_financial_statements_per_partner" initial={{ startDate: DateUtils.now(-90), endDate: DateUtils.now() }}>
                    <TawreedFormField name="startDate" title="lbl_reports_start_date" className="field col-12 md:col-6">
                        <TawreedCalendar render="form" name="startDate" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="endDate" title="lbl_reports_end_date" className="field col-12 md:col-6">
                        <TawreedCalendar render="form" name="endDate" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="operation" title="lbl_reports_financial_statements_opertion" className="field col-12 md:col-6">
                        <TawreedDropdown render='form' name='operation' options={options} showClear />
                    </TawreedFormField>
                </TawreedReportGenerator>
            }
            {
                user?.roleCode === 'CUSTOMER_ADMIN' &&
                <TawreedReportGenerator partnerType={PartnerType.Customer} name="statements-per-partner" title="lbl_reports_financial_statements_per_partner" initial={{ startDate: DateUtils.now(-90), endDate: DateUtils.now() }}>
                    <TawreedFormField name="startDate" title="lbl_reports_start_date" className="field col-12 md:col-6">
                        <TawreedCalendar render="form" name="startDate" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="endDate" title="lbl_reports_end_date" className="field col-12 md:col-6">
                        <TawreedCalendar render="form" name="endDate" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="operation" title="lbl_reports_financial_statements_opertion" className="field col-12 md:col-6">
                        <TawreedDropdown render='form' name='operation' options={options} showClear />
                    </TawreedFormField>
                </TawreedReportGenerator>
            }
            {
                (
                    user?.roleCode === 'ADMIN' ||
                    user?.roleCode === 'MANAGER' ||
                    user?.roleCode === 'SYS_ADMIN' ||
                    user?.roleCode === 'SALES' ||
                    user?.roleCode === 'FINANCE') &&

                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="col-12">
                    <TabPanel header={tawreedTranslationService.translate('lbl_reports_financial_statements_per_partner_store')} >
                        <TawreedReportGenerator partnerType={PartnerType.Store} name="statements-per-partner" title="" initial={{ startDate: DateUtils.now(-90), endDate: DateUtils.now() }}>
                            <TawreedFormField name="startDate" title="lbl_reports_start_date" className="field col-12 md:col-6">
                                <TawreedCalendar render="form" name="startDate" rules={{ required: 'msg_field_is_required' }} />
                            </TawreedFormField>

                            <TawreedFormField name="endDate" title="lbl_reports_end_date" className="field col-12 md:col-6">
                                <TawreedCalendar render="form" name="endDate" rules={{ required: 'msg_field_is_required' }} />
                            </TawreedFormField>

                            <TawreedFormField name="partner" title="lbl_reports_financial_statements_store" className="field col-12 md:col-6">
                                <PartnerAutoCompleteComponent partnerType={PartnerType.Store} autoCompleteName="partner" autoCompleteField="partnerName" autoCompleteRender="form" autoCompleteRules={{ required: 'msg_field_is_required' }} />
                            </TawreedFormField>

                            <TawreedFormField name="operation" title="lbl_reports_financial_statements_opertion" className="field col-12 md:col-6">
                                <TawreedDropdown render='form' name='operation' options={options} showClear />
                            </TawreedFormField>
                        </TawreedReportGenerator>
                    </TabPanel>
                    <TabPanel header={tawreedTranslationService.translate('lbl_reports_financial_statements_per_partner_customer')} >
                        <TawreedReportGenerator partnerType={PartnerType.Customer} name="statements-per-partner" title="" initial={{ startDate: DateUtils.now(-90), endDate: DateUtils.now() }}>
                            <TawreedFormField name="startDate" title="lbl_reports_start_date" className="field col-12 md:col-6">
                                <TawreedCalendar render="form" name="startDate" rules={{ required: 'msg_field_is_required' }} />
                            </TawreedFormField>

                            <TawreedFormField name="endDate" title="lbl_reports_end_date" className="field col-12 md:col-6">
                                <TawreedCalendar render="form" name="endDate" rules={{ required: 'msg_field_is_required' }} />
                            </TawreedFormField>

                            <TawreedFormField name="partner" title="lbl_reports_financial_statements_customer" className="field col-12 md:col-6">
                                <PartnerAutoCompleteComponent partnerType={PartnerType.Customer} autoCompleteName="partner" autoCompleteField="partnerName" autoCompleteRender="form" autoCompleteRules={{ required: 'msg_field_is_required' }} />
                            </TawreedFormField>

                            <TawreedFormField name="operation" title="lbl_reports_financial_statements_opertion" className="field col-12 md:col-6">
                                <TawreedDropdown render='form' name='operation' options={options} showClear />
                            </TawreedFormField>
                        </TawreedReportGenerator>
                    </TabPanel>

                </TabView>
            }
        </React.Fragment>
    )
}
