import { JObject } from "../../../../../common/data/models"
import { DateUtils } from "../../../../../helpers/date"
import { CustomerCartDto } from "../../domain"
import { CustomerCart } from "../models"
import { CustomerStoreCartMapper } from "./customer-store-cart"

export const CustomerCartMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {CustomerCart}
     */
    fromJson: (json: JObject): CustomerCart => {
        return {
            cartId: json['cartId'],
            carts: json['carts'] ? Array.from<JObject>(json['carts']).map((e) => CustomerStoreCartMapper.fromJson(e)) : [],
            valid: json['valid'],
            message: json['message'],
            loyaltyBalance: json['loyaltyBalance'],
            orderTotals: json['orderTotals'],
            subTotals: json['subTotals'],
            shippingTotals: json['shippingTotals'],
            totalDiscounts: json['totalDiscounts'],
            itemsCountTotal: json['itemsCountTotal'],
            itemsQuantityTotal: json['itemsQuantityTotal'],
            ordersCountTotal: json['ordersCountTotal'],
            customer: json['customer'],
            customerId: json['customerId'],
            showPlaceAllOrders: json['showPlaceAllOrders'],
            itemsUpdated: json['itemsUpdated'] ? Array.from<string>(json['itemsUpdated']).map(e => '' + e) : undefined,
            grossTotal:json['grossTotal'],
            totalTax:json['totalTax'],
            total:json['total'],
            priceListName:json['priceListName'],
            priceListId:json['prceListId'],
            statusId: json['statusId'],
            typeId: json['typeId']
        }
    },
    fromJson1: (json: JObject): CustomerCart => {
        return {
            cartId: json['cartId'],
            carts:  [CustomerStoreCartMapper.fromJson(json)],//json['carts'] ? Array.from<JObject>(json).map((e) => CustomerStoreCartMapper.fromJson(e)) : [],
            valid: json['valid'],
            message: json['message'],
            loyaltyBalance: json['loyaltyBalance'],
            orderTotals: json['orderTotals'],
            subTotals: json['subTotals'],
            shippingTotals: json['shippingTotals'],
            totalDiscounts: json['totalDiscounts'],
            itemsCountTotal: json['itemsCountTotal'],
            itemsQuantityTotal: json['itemsQuantityTotal'],
            ordersCountTotal: json['ordersCountTotal'],
            customer: json['customer'],
            customerId: json['customerId'],
            showPlaceAllOrders: json['showPlaceAllOrders'],
            itemsUpdated: json['itemsUpdated'] ? Array.from<string>(json['itemsUpdated']).map(e => '' + e) : undefined,
            grossTotal:json['grossTotal'],
            totalTax:json['totalTax'],
            total:json['total'],
            priceListName:json['priceListName'],
            priceListId:json['prceListId']
        }
    },
    /**
     *
     */
    toJson: (cart: CustomerCart): JObject => {
        return {
            cartId: cart.cartId,
        }
    },

    /**
     * Create cart model from json object
     * @param dto
     * @returns {CustomerCart}
     */
    fromDto: (dto: CustomerCartDto): CustomerCart => {
        return {
            cartId: dto.cartId!,
            carts: dto.carts ? Array.from(dto.carts).map((e) => CustomerStoreCartMapper.fromDto(e)) : [],
            valid: dto.valid!,
            message: dto.message!,
            loyaltyBalance: dto.loyaltyBalance!,
            orderTotals: dto.orderTotals!,
            subTotals: dto.subTotals!,
            shippingTotals: dto.shippingTotals!,
            totalDiscounts: dto.totalDiscounts!,
            itemsCountTotal: dto.itemsCountTotal!,
            itemsQuantityTotal: dto.itemsQuantityTotal!,
            ordersCountTotal: dto.ordersCountTotal!,
            customer: dto.customer!,
            customerId: dto.customer!.partnerId!,
            showPlaceAllOrders: dto.showPlaceAllOrders ?? false,
            itemsUpdated: dto.itemsUpdated,
            grossTotal:dto.grossTotal,
            totalTax:dto.totalTax,
            total:dto.total,
            priceListName:dto.priceListName,
            priceListId:dto.priceListId,
        }
    },

    /**
     *
     */
    toDto: (cart: CustomerCart): CustomerCartDto => {
        console.log("toDto",cart);
        return {
            cartId: cart.cartId,
            carts: cart.carts ? Array.from(cart.carts).map((e) => CustomerStoreCartMapper.toDto(e)) : undefined,
            valid: cart.valid,
            message: cart.message,
            loyaltyBalance: cart.loyaltyBalance,
            orderTotals: cart.orderTotals,
            subTotals: cart.subTotals,
            shippingTotals: cart.shippingTotals,
            totalDiscounts: cart.totalDiscounts,
            itemsCountTotal: cart.itemsCountTotal,
            itemsQuantityTotal: cart.itemsQuantityTotal,
            ordersCountTotal: cart.ordersCountTotal,
            customer: typeof (cart.customer) === 'string' ? { partnerId: cart.customerId, partnerName: cart.customer } : cart.customer,
            store: cart.store ? (typeof (cart.store!) === 'string' ? { partnerId: cart.storeId, partnerName: cart.store } : cart.store): undefined,
            storeId: cart.storeId,
            showPlaceAllOrders: cart.showPlaceAllOrders,
            itemsUpdated: cart.itemsUpdated,
            grossTotal:cart.grossTotal,
            totalTax:cart.totalTax,
            total:cart.total,
            priceListName:cart.priceListName,
            priceListId:cart.priceListId,
            salesOfferId: cart.salesOfferId,
            salesOfferTitle: cart.salesOfferTitle,
            statusId: cart.statusId,
            typeId: cart.typeId,
            createdAt: cart?.createdAt ? DateUtils.fromISO(cart?.createdAt!): undefined,
            updatedAt: cart?.updatedAt ? DateUtils.fromISO(cart?.updatedAt!) : undefined,
        }
    },
}
