import React from "react";
import { PrimeIcons } from "primereact/api";
import { useFormContext } from "react-hook-form";


import { TawreedActionName } from "../../../../common/actions";
import { TawreedControlProps } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { GlobalContext } from "../../../../context";
import { ObjectUtils } from "../../../../helpers/object";
import { TawreedFile } from "../../../upload/domain";
import { BusinessTypeFile, PartnerFile, PartnerType } from "../../data";
import { PartnersFilesService } from "../../domain";
import { TawreedInputUpload, TawreedInputUploadProps } from "../../../../common/components/form/controls/input-upload";

export type PartnerFilesProps = TawreedControlProps<Array<TawreedFile>> & { className?: string, partnerType: PartnerType; businessType: number; };

export const PartnerFiles: React.FC<PartnerFilesProps> = (props) => {

    // 
    const { constants: { constants } } = React.useContext(GlobalContext);

    //
    const form = useFormContext();
    const service = React.useMemo(() => new PartnersFilesService(), []);
    const [inputUploadProps, setInputUploadProps] = React.useState<TawreedInputUploadProps>();

    React.useEffect(
        () => {
            let mounted = true;
            if (mounted) {
                const extendsFiles = (arr1?: BusinessTypeFile[], arr2?: PartnerFile[]) => {
                    if (!arr1 || !arr1.length) {
                        return [];
                    }
                    return arr1
                        .map(i1 => {
                            let value = undefined;
                            if (arr2 && arr2.length) {
                                value = arr2.find(i2 => i1.fileName === i2.fileName);
                            }
                            return { ...i1, ...value };
                        })
                        .filter(i1 => !props.disabled || i1.value);
                };

                const files = service.getPartnerFilesByType(props.partnerType, props.businessType, constants);

                if (!files || !files.length) {
                    setInputUploadProps(undefined);
                } else {
                    const e: TawreedInputUploadProps = {
                        ...props,
                        className: 'field col-12',
                        title: 'lbl_partners_files',
                        children: <React.Fragment />,
                        onUpload: props.disabled ? undefined : { name: TawreedActionName.PartnersFilesUpload, type: 'statefull', title: 'lbl_upload', icon: PrimeIcons.UPLOAD, className: 'p-button-primary' },
                        onRemove: props.disabled ? undefined : { name: TawreedActionName.PartnersFilesUpload, type: 'statefull', title: 'lbl_upload', icon: PrimeIcons.TRASH, className: 'p-button-danger' },
                        onDownload: { name: TawreedActionName.PartnersFilesDownload, type: 'statefull', title: 'lbl_download', icon: PrimeIcons.DOWNLOAD, className: 'p-button-info' },
                        service: service,
                        rules: {
                            validate: (v: PartnerFile[]) => {
                                const ee = v.filter(x => x.isRequired && !x.value);
                                const eee = v.map(x => x.fileName).join(', ');

                                return ee && ee.length ? tawreedTranslationService.translate('msg_partners_files_required', eee) : true;
                            }
                        },
                    }

                    if (e.render === 'standalone') {
                        e.value = extendsFiles(files, e.value);
                    } else if (e.name && form !== undefined) {
                        let value = form.getValues(e.name);
                        if (e.render === 'form-nested') {
                            let nested = ObjectUtils.getNested(value, e.nested);
                            nested = extendsFiles(files, nested);
                            value[e.nested] = nested;
                        } else {
                            value = extendsFiles(files, value);
                        }
                        console.log("value",value);
                        form.setValue(e.name, value, { shouldDirty: false });
                    }
                    setInputUploadProps(e);
                }
            }
            return () => { mounted = false; }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.render, props.businessType, props.businessType, service, constants, form]);

    if (!inputUploadProps) {
        return <React.Fragment />
    }
    return (
        <TawreedInputUpload {...inputUploadProps}></TawreedInputUpload>
    )
}
