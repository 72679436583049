import React, { useEffect } from "react";
import { GlobalContext } from "../../../../context";
import { DashboardFinancePartnersChart } from "./dashboard-finance-partners-chart";
import { DashboardService } from "../../domain";
import { PartnersChartDto } from "../../domain/dtos/dashboard";
import { tawreedTranslationService } from "../../../../common/translations";
import { FinancePartnerDialog } from "./finance-partner-dialog";
import { DateUtils } from "../../../../helpers/date";


export type DashboardLiabilitiesState = {
    partners: PartnersChartDto | undefined;
    loading: boolean
}
export type DashboardDateState = {
    startDate: Date;
    endDate: Date;
}
export const FinanceDashboardComponent: React.FC = (_props) => {
    const service = React.useMemo(() => new DashboardService(), []);
    const [type, setType] = React.useState<number | undefined>(undefined);
    const [xlsxReportURI, setXlsxReportURI] = React.useState<string | undefined>(undefined);
    const [pdfReportURI, setPdfReportURI] = React.useState<string | undefined>(undefined);
    
    const onPrint = (report: string) => {
        return service.print(report)
            .then(() => {
            })
            .catch(err => console.error(err));
    };

    const onDetials = (type: number, xlsxReportURI: string, pdfReportUri: string) => {
        setType(type);
        setXlsxReportURI(xlsxReportURI);
        setPdfReportURI(pdfReportUri);
    };
    
    const [storesLiabilities, setStoresLiabilities] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });
    const [storesDues, setStoresDues] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });
    const [topupBalances, setTopupBalances] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });
    const [customerWallet, setCustomerWallet] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });
    const [bankBalances, setBankBalances] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });

    const [storeInvoices, setStoreInvoices] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });

    const [storeinvoicesDate, setStoreInvoicesDate] = React.useState<DashboardDateState>({
        startDate: DateUtils.now(),
        endDate: DateUtils.now(),
    });

    useEffect(()=>{
        service.getFinanceReportForPartner(1)
        .then(res => 
        {
            setStoresLiabilities({partners: res, loading: false})
        })
        .catch(() => {});
        service.getFinanceReportForPartner(2)
        .then(res => 
        {
            setStoresDues({partners: res, loading: false})
        })
        .catch(() => {});
        service.getFinanceReportForPartner(3)
        .then(res => 
        {
            setTopupBalances({partners: res, loading: false})
        })
        .catch(() => {});
        service.getFinanceReportForPartner(4)
        .then(res => 
        {
            setCustomerWallet({partners: res, loading: false})
        })
        .catch(() => {});
        service.getFinanceReportForPartner(5)
        .then(res => 
        {
            setBankBalances({partners: res, loading: false})
        })
        .catch(() => {});
        service.getStoreInvoices(storeinvoicesDate.startDate, storeinvoicesDate.endDate)
        .then(res => 
        {
            setStoreInvoices({ partners: res, loading: false})
        })
        .catch(() => {});
    },[service]);

    const onDateChange = (sender: 'startDate' | 'endDate', value: Date) => {
        setStoreInvoicesDate({ ...storeinvoicesDate, [sender]: value});
        
    }

    useEffect(()=>{
        setStoreInvoices({ ...storeInvoices, loading: true})
        service.getStoreInvoices(storeinvoicesDate.startDate!, storeinvoicesDate.endDate!)
        .then(res => 
        {
            setStoreInvoices({partners: res, loading: false})
        })
        .catch(() => {});
    },
    [storeinvoicesDate.startDate, storeinvoicesDate.endDate]);

    return (
        <React.Fragment>
             <div className="grid">
            <div className="col-12">
                <h3>{tawreedTranslationService.translate("lbl_dashboard")}</h3>
            </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showDetails={onDetials}
                            type={1}
                            className=""
                            displayPartner={true}
                            showCalendar={false}
                            title="lbl_stores_liabilities"
                            showTotal={true}
                            //type={}
                            onChange={()=>{}}
                            loading={storesLiabilities?.loading}
                            dto={storesLiabilities?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showDetails={onDetials}
                            type={2}
                            title="lbl_stores_dues"
                            showTotal={true}
                            showCalendar={false}
                            onChange={()=>{}}
                            className=""
                            displayPartner={true}
                            loading={storesDues?.loading}
                            dto={storesDues?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            title="lbl_topup_balances"
                            showDetails={onDetials}
                            type={3}
                            showTotal={false}
                            showCalendar={false}
                            onChange={()=>{}}
                            className=""
                            displayPartner={true}
                            loading={topupBalances?.loading}
                            dto={topupBalances?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showTotal={true}
                            showCalendar={false}
                            onChange={()=>{}}
                            showDetails={onDetials}
                            type={4}
                            title="lbl_customer_wallet_balances"
                            className=""
                            displayPartner={true}
                            loading={customerWallet?.loading}
                            dto={customerWallet?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showDetails={onDetials}
                            type={5}
                            onChange={()=>{}}
                            title="lbl_bank_balances"
                            showTotal={false}
                            displayPartner={false}
                            className=""
                            showCalendar={false}
                            loading={bankBalances?.loading}
                            dto={bankBalances?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showDetails={onDetials}
                            type={6}
                            title="lbl_store_invoices"
                            showTotal={false}
                            displayPartner={false}
                            showCalendar={true}
                            onChange= {onDateChange}
                            startDate={storeinvoicesDate.startDate}
                            endDate={storeinvoicesDate.endDate}
                            className=""
                            loading={storeInvoices?.loading}
                            dto={storeInvoices?.partners}/>
                    </div>
                    </div>
                <FinancePartnerDialog
                hide={()=>{setType(undefined); 
                    setPdfReportURI(undefined);
                    setXlsxReportURI(undefined);
                }}
                type={type}
                print={onPrint}
                pdfReportURI = {pdfReportURI?? ""}
                xlsxReportURI={xlsxReportURI?? ""}
                startDate={storeinvoicesDate.startDate}
                endDate={storeinvoicesDate.endDate}
                ></FinancePartnerDialog>
                </React.Fragment>

                
    );
}