import { JObject } from "../../../../common/data/models"
import { NotificationCampaign } from "../../domain";

export const NotificationCampaignMapper = {

    /**
     *
     */
    toJson: (obj: NotificationCampaign): JObject => {
        return {
            title: obj.title,
            message: obj.message,
            partnerType: obj.partnerType,
            recordId: obj.postId,
            zones: obj.zones && obj.zones.length ? obj.zones.map(e => e.zoneId) : undefined,
        }
    },
}
