import { JObject } from "../../../../../common/data/models";
import { StoreProduct } from "../models";
import { StoreProductDto } from "../../domain";
import { ProductMapper } from "../../../products/data";
import { PartnerMapper } from "../../../../partners/data";
import { DateUtils } from "../../../../../helpers/date";
import { BonusMapper } from "./bonus";
import { TagMapper } from "./tag";

export const StoreProductMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {StoreProduct}
     */
    fromJson: (json?: JObject): StoreProduct => {
        return {
            storeProductId: json?.storeProductId,
            product: ProductMapper.fromJson({
                productId: json?.productId,
                productName: json?.productName,
                productNameEn: json?.productNameEn,
                active: json?.productActive,
                displayName: json?.displayName,
                translations: [
                    {
                        langCode: 'ar',
                        productName: json?.productNameAr,
                    },
                    {
                        langCode: 'en',
                        productName: json?.productNameEn,
                    },
                ]
            }),
            store: PartnerMapper.fromJson({
                partnerId: json?.storeId,
                active: json?.storeActive,
                partnerName: json?.storeName,
            }),
            availableQuantity: json?.availableQuantity,
            discountPercent: json?.discountPercent,
            expireDate: json?.expireDate ? DateUtils.fromISO(new Date(json?.expireDate).toISOString()) : undefined,
            quantity: json?.quantity,
            quantityOrdMax: json?.quantityOrdMax,
            quantityOrdMin: json?.quantityOrdMin,
            retailPrice: json?.retailPrice,
            salePrice: json?.salePrice,
            tax: json?.tax,
            storeItemId: json?.storeItemId,
            price: json?.price,
            discountType: json?.discountType,
            discountValue: json?.discountValue,
            priceListName: json?.priceListName,
            priceListId: json?.priceListId,
            titleAr: json?.titleAr,
            titleEn: json?.titleEn,
            productTax: json?.productTax,
            imageUrl: json?.imageUrl,
            active: json?.active,
            categoryId: json?.categoryId,
            description: json?.description,
            bonuses: json?.bonuses ? json?.bonuses.map((el: JObject | undefined) => BonusMapper.fromJson(el)) : [],
            maximumOrderQuantity: json?.maximumOrderQuantity,
            minimumOrderQuantity: json?.minimumOrderQuantity,
            sellingPrice: json?.sellingPrice,
            tags: json?.storeProductTags?.map((el:any)=> TagMapper.fromJson(el))
        }
    },

    /**
     *
     */
    toJson: (model: StoreProduct): JObject => {
        return { ...model, productId: model.product.productId, storeId: model.store.partnerId,
             expireDate: model.expireDate ? DateUtils.toISO(model.expireDate!,"date","end") : model.expireDate };
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {StoreProduct}
     */
    fromDto: (dto: StoreProductDto): StoreProduct => {
        return {
            storeProductId: dto.storeProductId!,
            product: ProductMapper.fromDto(dto.product!),
            store: PartnerMapper.fromDto(dto.store!),
            availableQuantity: dto.availableQuantity!,
            discountPercent: dto.discountPercent!,
            expireDate: dto.expireDate!,
            quantity: dto.quantity!,
            quantityOrdMax: dto.quantityOrdMax!,
            quantityOrdMin: dto.quantityOrdMin!,
            retailPrice: dto.retailPrice!,
            salePrice: dto.salePrice!,
            tax: dto.tax!,
            storeItemId: dto.storeItemId!,
            titleAr: dto?.titleAr?.trim(),
            titleEn: dto?.titleEn?.trim(),
            productTax: dto?.productTax,
            imageUrl: dto?.imageUrl,
            active: dto?.active,
            categoryId: dto?.categoryId,
            description: dto?.description,
            bonuses: dto?.bonuses?.map((el: any) => BonusMapper.fromDto(el)) ?? [],
            maximumOrderQuantity: dto?.maximumOrderQuantity,
            minimumOrderQuantity: dto?.minimumOrderQuantity,
        }
    },

    /**
     *
     */
    toDto: (model: StoreProduct): StoreProductDto => {
        return {
            storeProductId: model.storeProductId,
            storeProductName: `${model.product?.productName} - ${model.store?.partnerName}`,
            product: ProductMapper.toDto(model.product),
            store: PartnerMapper.toDto(model.store),
            availableQuantity: model.availableQuantity!,
            discountPercent: model.discountPercent!,
            expireDate: model.expireDate!,
            quantity: model.quantity!,
            quantityOrdMax: model.quantityOrdMax!,
            quantityOrdMin: model.quantityOrdMin!,
            retailPrice: model.retailPrice!,
            salePrice: model.salePrice!,
            tax: model.tax!,
            storeItemId: model.storeItemId!,
            price: model.price!,
            discountValue: model.discountValue!,
            discountType: model.discountType!,
            priceListName: model.priceListName!,
            priceListId: model.priceListId!,
            titleAr: model?.titleAr!,
            titleEn: model?.titleEn!,
            productTax: model?.productTax!,
            imageUrl: model?.imageUrl!,
            active: model?.active!,
            categoryId: model?.categoryId,
            description: model?.description,
            bonuses: model?.bonuses?.map((el: any) => BonusMapper.toDto(el)) ?? [],
            maximumOrderQuantity: model?.maximumOrderQuantity,
            minimumOrderQuantity: model?.minimumOrderQuantity,
            sellingPrice: model?.sellingPrice,
            tags: model?.tags?.map((el:any)=> TagMapper.toDto(el))
        };
    },
    toSellerDto: (model: StoreProduct): StoreProductDto => {
        return {
            storeProductId: model.storeProductId,
            storeProductName: model.product?.productName,
            product: ProductMapper.toDto(model.product),
            store: PartnerMapper.toDto(model.store),
            availableQuantity: model.availableQuantity!,
            discountPercent: model.discountPercent!,
            expireDate: model.expireDate!,
            quantity: model.quantity!,
            quantityOrdMax: model.quantityOrdMax!,
            quantityOrdMin: model.quantityOrdMin!,
            retailPrice: model.retailPrice!,
            salePrice: model.salePrice!,
            tax: model.tax!,
            storeItemId: model.storeItemId!,
            price: model.price!,
            discountValue: model.discountValue!,
            discountType: model.discountType!,
            priceListName: model.priceListName!,
            priceListId: model.priceListId!,
            titleAr: model?.titleAr!,
            titleEn: model?.titleEn!,
            productTax: model?.productTax!,
            imageUrl: model?.imageUrl!,
            active: model?.active!,
            categoryId: model?.categoryId,
            description: model?.description,
            bonuses: model?.bonuses?.map((el: any) => BonusMapper.toDto(el)) ?? [],
            maximumOrderQuantity: model?.maximumOrderQuantity,
            minimumOrderQuantity: model?.minimumOrderQuantity,
            tags: model?.tags?.map((el:any)=> TagMapper.toDto(el))
        };
    },
}

