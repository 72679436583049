import React from "react";
import { PostDto, PostService } from "../../domain";
import { TawreedAutoComplete, TawreedControlRenderModeWithoutNested, TawreedControlRules } from "../../../../common";
import { PostAutocompleteItemTemplate } from "./post-autocomplete.item-template";
import { GlobalContext } from "../../../../context";
import { AutoCompleteChangeParams, AutoCompleteSelectParams } from "primereact/autocomplete";
import { AuthUtils } from "../../../auth/domain";

export type PostAutoCompleteComponentProps = {

    //
    service?: PostService;

    // autocomplete
    autoCompleteValue?: PostDto;
    autoCompleteName: string;
    autoCompleteRender: TawreedControlRenderModeWithoutNested;
    autoCompleteDisabled?: boolean;
    autoCompleteRules?: TawreedControlRules;
    autoCompleteField: string;
    autoCompleteOnSelect?(e: AutoCompleteSelectParams): void;
    autoCompleteOnChange?(e: AutoCompleteChangeParams): void;
};

export const PostAutoCompleteComponent: React.FC<PostAutoCompleteComponentProps> = (props) => {

    const context = React.useContext(GlobalContext);
    const service: PostService = props.service ?? new PostService();
    
    var lang = AuthUtils.current().language;
    const search = (q: string) => {
        return service
            .searchAutoComplete(q,lang)
            .then(res => {
                const result = [];
                if (res.data && res.data.content) {
                    result.push(...res.data.content);
                }
                return result;
            });
    };

    return (
        <TawreedAutoComplete name={props.autoCompleteName}
                             render={props.autoCompleteRender}
                             disabled={props.autoCompleteDisabled}
                             rules={props.autoCompleteRules}
                             field={props.autoCompleteField}
                             value={props.autoCompleteValue}
                             scrollHeight={'25em'}
                             onSelect={props.autoCompleteOnSelect}
                             onChange={props.autoCompleteOnChange}
                             onSearch={search}
                             itemTemplate={e => <PostAutocompleteItemTemplate rowData={e}/>}/>
    );
}
