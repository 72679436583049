import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect, useContext } from 'react';
import { JObject } from '../../../../common/data/models';
import { tawreedTranslationService } from '../../../../common/translations';
import { GlobalContext } from '../../../../context';
import { DashboardService } from '../../domain';
import { TawreedDataTableFilterMeta, TawreedTable, TawreedTableProps } from '../../../../common';
import { CssUtils } from '../../../../helpers/css';
import { TawreedTableFormatters } from '../../../../common/components/table/formatters';
import { PrimeIcons } from 'primereact/api';
import { TawreedAction, TawreedActionName } from '../../../../common/actions';
import { AuthUtils } from '../../../auth/domain';


export interface FinancePartnerDialogProps {
    type: number | undefined;

    hide: () => void;
    
    print :(value: string) =>Promise<void>;
    xlsxReportURI: string;
    pdfReportURI: string;
    startDate?: Date,
    endDate?: Date
}

export const FinancePartnerDialog: FC<FinancePartnerDialogProps> = ({ type, hide, print,xlsxReportURI, pdfReportURI, startDate, endDate}) => {


    const FinancePartnerDialogFooter = () => {
        return  <div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />

        </div>;
    }
    const FinancePartnerDialogHeader= () => {
        return <div>
        <p>{type ===1 ?
            tawreedTranslationService.translate("lbl_stores_liabilities"):
            (
                type === 2 ?
                tawreedTranslationService.translate("lbl_stores_dues")
                :
                (
                    type === 3 ?
                    tawreedTranslationService.translate("lbl_topup_balances")
                    :
                    (
                        type === 4 ?
                        tawreedTranslationService.translate("lbl_customer_wallet_balances")
                        :
                        (type === 5 ?
                        tawreedTranslationService.translate("lbl_bank_balances")
                        :
                        tawreedTranslationService.translate("lbl_store_invoices")
                        )
                    )
                )
            )}</p>
    </div>
    }
    const service = React.useMemo(() => new DashboardService(), []);

    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);

    const lang = AuthUtils.current().language;
    const getFilterDependOnType = () => {
        var arr:TawreedDataTableFilterMeta = {};
        switch (type) {
            case 1:
                arr = 
                {
                        'partnerType': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 1,
                                    matchMode: 'equals',
                                },
                            ]
                        },
                        'balance': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 0,
                                    matchMode: 'gt',
                                },
                            ]
                        },
                    };
                    break;
            case 2:
                arr = 
                {
                        'partnerType': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 1,
                                    matchMode: 'equals',
                                },
                            ]
                        },
                        'balance': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 0,
                                    matchMode: 'lt',
                                },
                            ]
                        },
                    };
                break;
            case 3:
                arr = 
                {
                        'partnerType': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 4,
                                    matchMode: 'equals',
                                },
                            ]
                        },
                        'active': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: true,
                                    matchMode: 'equals',
                                },
                            ]
                        },
                    };
                break;
            case 4:
                arr = 
                {
                        'partnerType': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 2,
                                    matchMode: 'equals',
                                },
                            ]
                        },
                        'balance': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 0,
                                    matchMode: 'gt',
                                },
                            ]
                        },
                    };
                break;
            case 5:
                arr = 
                {
                        'accountType': {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: 14,
                                    matchMode: 'equals',
                                },
                            ]
                        },
                    };    
            break;
        }
        return arr;
    }
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'balanceId',
        name: '',
        title: '',
        ds: {
            mode: 'lazy',
            onSearch: type !== 6 ? (q) => service.search(q) : (q) => service.searchStoreInvoices(startDate!, endDate!, q),
        },
        columns: type !== 6 ? [
            {
                field: "partnerName",
                header: "lbl_reports_trialbalance_partnername",
                sortable: true,
                filter: true,
                style: CssUtils.width('30%')
            },
            {
                field:"accountTitle",
                header: "lbl_reports_trialbalance_accounttitle",
                sortable: true,
                filter: true,
                style: CssUtils.width('30%', CssUtils.color('var(--danger-color)')),
            },
            {
                field: "balance",
                header: "lbl_reports_trialbalance_balance",
                sortable: true,
                filter: false,
                alignHeader:lang === "en" ? 'right' : 'left' ,
                ...TawreedTableFormatters.decimal('balance', user?.scale! ,undefined, CssUtils.width('25%')),
            }

        ] : [
            {
                field: "partnerName",
                header: "lbl_reports_trialbalance_partnername",
                sortable: true,
                sortField:"partner_name",
                filter: false,
                style: CssUtils.width('30%')
            },
            {
                field:"total",
                header: "lbl_reports_invoices",
                sortable: true,
                filter: false,
                ...TawreedTableFormatters.decimal('total', user?.scale! ,undefined, CssUtils.width('25%')),
                style: CssUtils.width('30%', CssUtils.color('var(--danger-color)')),
            }
        ],
        toolbar: {
            actions: [

                new TawreedAction(TawreedActionName.ReportsExport, 'stateless', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => print(xlsxReportURI)),
                new TawreedAction(TawreedActionName.ReportsExport, 'stateless', 'lbl_reports_export_as_pdf', PrimeIcons.FILE_PDF, () => print(pdfReportURI))
            ],
        },
        filter: {
            filterDisplay: 'menu',
            initial: getFilterDependOnType(),
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: type !== 6 ? 'balance': 'total',
                sortOrder: type !== 6 ? 1: -1,
                multiSortMeta: undefined,
            },
        },

    }

    return (
        
        <Dialog maximizable resizable style={{ minWidth: '80%' }} 
            header={<FinancePartnerDialogHeader />} 
            visible={type? true: false} footer={<FinancePartnerDialogFooter />} onHide={hide} draggable={false}
             position="top" closable={true} closeOnEscape={true}>
            <TawreedTable  {...tawreed} />
        </Dialog>
    )
}
