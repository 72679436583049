import {PartnersChartDto} from "../../domain";
import {JObject} from "../../../../common/data/models";

export const PartnersChartMapper = {

    fromJson(json?: Array<JObject>): PartnersChartDto {
        if (!json || !json.length) {
            return {
                amount: {
                    color: '#42A5F5',
                    value: [],
                },
                count: {
                    color: '#FFA726',
                    value: [],
                },
                reportURI: ''
            };
        }
        return {
            amount: {
                color: '#42A5F5',
                value: json.map(e => {
                    return {
                        name: e.partnerId,
                        label: e.partnerName,
                        value: e.totalAmount,
                    }
                }),
            },
            count: {
                color: '#FFA726',
                value: json.map(e => {
                    return {
                        name: e.partnerId,
                        label: e.partnerName,
                        value: e.totalCount,
                    }
                }),
            },
            reportURI: json.length > 0 ? json.at(0)?.xlsReportURI : ''
        };
    },
}
