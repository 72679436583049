import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { WindowUtils } from "../../../../helpers/window";
import { DashboardRepository } from "../../data";
import { FinancePartnerDto, PartnersChartDto, StoreInvoiceDto } from "../dtos/dashboard";

export class DashboardService {
    private readonly repository = new DashboardRepository();

    public getFinanceReportForPartner(type:number): Promise<PartnersChartDto> {
        return this.repository.getFinanceReportForPartner(type);
    }
    public print(reportUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                WindowUtils.print(reportUrl);
                resolve(true);
            } catch {
                reject();
            }
        });
    };
    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<FinancePartnerDto>> {
        return this.repository.search(request);
    }

    public getStoreInvoices(startDate: Date, endDate: Date): Promise<PartnersChartDto> {
        return this.repository.getStoreInvoices(startDate, endDate);
    }

    public searchStoreInvoices(startDate: Date, endDate: Date,
         request: PaginatedRequestParams): Promise<PaginatedResponse<StoreInvoiceDto>> {
        return this.repository.searchStoreInvoices(startDate, endDate,request);
    }
}