import React, { useEffect, useMemo, useRef, useState } from "react";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Button } from "primereact/button";
import { StoreProduct } from "../../../../catalog/store-products/data";
import { PartnerDto } from "../../../../partners/domain";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { priceListDto, StoreProductsService } from "../../../../catalog/store-products/domain";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { ObjectUtils } from "../../../../../helpers/object";
import { PrimeIcons } from "primereact/api";
import { QuantityDialog } from "./quantity-dialog";
import { CssUtils } from "../../../../../helpers/css";
import { AuthUtils } from "../../../../auth/domain";
import { Panel } from "primereact/panel";
import { StoreMultiSelect } from "../../../../partners/presentaion/components";
import { TawreedDropdown, TawreedFormField, TawreedMenuDropdown } from "../../../../../common";
import { PriceListServcie } from "../../../../catalog/store-products/domain/services/price-list.service";
import { GlobalContext } from "../../../../../context";
import { IBonusOperations, IBonusType } from "../../../../constants";
import { Tooltip } from "primereact/tooltip";

export interface CartFormProductsState {
    isVisible: boolean;
    storeProduct?: StoreProduct;
}

export interface CartFormProductsProps {
    className?: string;
    onSelect: (p: StoreProduct) => void;
    disabled: boolean;
    zoneId: number;
    actor: PartnerDto;
    priceListId?: any;
    customerId: number;
    storeId?: number;
    changeQuantity: (quantity: number, bonusType: string) => void
}
export interface StorePriceSearchState {

    stores: Array<PartnerDto>;
    priceScheme: number;
}

export const CartFormProducts: React.FC<CartFormProductsProps> = (props) => {
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);
    const ref = useRef<any>(null);
    const [state, setState] = useState<CartFormProductsState>({
        isVisible: false,
        storeProduct: undefined,
    });

    const [page, setpage] = useState(0);
    var keyword: string | undefined = undefined;

    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);
    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);

    useEffect(() => {
        if (priceLists.length == 0) {
            priceListService.getAll().then((res) => {
                setPriceLists(res);
            });
        }
    }, [props.zoneId]);

    const { auth: { user } } = React.useContext(GlobalContext);
    const [reload, setReload] = useState<boolean>(false);
    const [search, setSearch] = useState<Partial<StorePriceSearchState>>({});
    const getBox = (discount: string) => {
        if (discount === 'fixed')
            return "JOD"
        if (discount === 'percentage')
            return "%"

        return "%+"
    }
    const rowClass = (data: any): object | string => {
        return {
            'bg-primary-reverse': data.tags && data.tags.filter((tag: any) => tag.tagId === 1).length > 0
        };
    }
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        //rowClassName: rowClass,
        ds: {
            mode: 'lazy',
            //asyncSearch: true,
            onSearch: (q) => {
                return service.searchSimilarWithPrice({
                    q: q.keyword ?? '',
                    zoneId: props.zoneId, actor: props.actor,
                    priceListId: props.priceListId ? props.priceListId : search.priceScheme,
                    storesList: props.storeId ? [{ partnerId: props.storeId }] : search.stores, customerId: props.customerId
                },
                    { ...q, page: reload || keyword !== q.keyword ? 0 : q.page }).then((res) => {

                        return res;
                    }).catch().finally(() => { keyword = q.keyword; setReload(false) });
            }
        },
        reload: reload,
        dataKey: 'storeProductId',
        name: 'tbl.store.products',
        columns: [
            {
                field: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr", //"product.productName",
                header: "lbl_store_products_product_id",
                sortable: true,
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                filter: false,
                filterField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                style: { ...CssUtils.width('20%') },
                bodyStyle: { 'unicode-bidi': 'plaintext' },
                body: (options: any) => {
                    return (
                        <div className='grid'>
                            <span className='col-10'>{AuthUtils.current().language === 'en' ?
                             options.titleEn : options.titleAr}</span>
                            {options.tags && options.tags.filter((tag: any) => tag.tagId === 1).length > 0 &&
                                <React.Fragment>
                                    <Tooltip target=".logo" mouseTrack mouseTrackLeft={10} />
                                    <img className='logo mt-2' data-pr-tooltip={tawreedTranslationService.translate("tags_nearly_expired")} style={{ color: 'red', width: '20px', height: '20px' }} src="/assets/images/alert.png" />
                                </React.Fragment>
                            }
                        </div>)
                }
            },
            {
                field: "store.partnerName",
                header: "lbl_store_products_store_name",
                sortable: true,
                sortField: 'storeName',
                filter: false,
                filterField: 'storeName',
                style: CssUtils.width('10%'),
            },
            {
                field: "expireDate",
                header: "lbl_store_product_expire_date",
                sortable: true,
                sortField: 'expireDate',
                filter: false,
                filterField: 'expireDate',
                body: (options: any) => {

                    return options.expireDate ? new Date(options.expireDate).toDateString().split(' ').splice(1, 3).join(' ') : ''
                },
                style: CssUtils.width('10%'),
            },

            {
                field: "priceListName",
                header: "lbl_price_scheme_price_list_name",
                sortable: true,
                filter: false,
                style: CssUtils.width('10%'),
            },
            {
                field: "sellingPrice",
                header: "lbl_selling_price1",
                sortable: true,
                filter: false,
                ...TawreedTableFormatters.decimal('sellingPrice', user?.scale, CssUtils.width('10%')),
                className: CssUtils.className.field.amount,
            },
            {
                dataType: "numeric",
                field: "tax",
                header: "lbl_store_products_tax",
                sortable: true,
                filter: false,
                ...TawreedTableFormatters.percentage('tax', user?.scale!),
                style: CssUtils.width('5%'),
                className: CssUtils.className.field.amount,
            },
            {
                field: "tax",
                header: "lbl_bonus",
                sortable: false,
                filter: false,
                style: CssUtils.width('10%'),
                className: CssUtils.className.field.amount,
                body: (options: any) => {
                    if (options.bonuses !== undefined &&
                        options.bonuses.length > 0) {
                        return <div className="bonuses">
                            {options.bonuses.map((e: any) => {
                                if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                    return <div className="bonuses-item">{e.quantity} + {e.bonus}</div>
                                return <></>
                            })
                            }
                        </div>
                    }
                    return <></>;
                },
            },
            {
                field: "tax",
                header: "lbl_Discounts",
                sortable: false,
                filter: false,
                style: CssUtils.width('10%'),
                className: CssUtils.className.field.amount,
                body: (options: any) => {
                    if (options.bonuses !== undefined &&
                        options.bonuses.length > 0) {
                        return <div className="bonuses">
                            {options.bonuses.map((e: any) => {
                                if (e.bonusType === IBonusType.DISCOUNT)
                                    return <div className="bonuses-item">{e.quantity}{e.bonusOperation === IBonusOperations.GREATER_THAN ? " < " : " * "}  {e.bonus} {getBox(e.discountType!)}</div>
                                return <></>
                            })
                            }
                        </div>
                    }
                    return <></>;
                },
            },
            {
                field: "actions",
                // header: "lbl_add_to_cart",
                body: (options: any) => {
                    return <Button
                        type="button"
                        style={{ width: "100%", height: "35px" }}
                        icon={PrimeIcons.SHOPPING_CART}
                        className="p-button-success"
                        onClick={() => {
                            props.onSelect(options);
                            setState(previous => { return { ...previous, isVisible: true, storeProduct: options }; });
                        }} />
                },
                style: CssUtils.width('12%'),
            }

        ],

        sort: {
            sortMode: 'single',
            initial: {
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",//'productName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
    }
    return (
        <React.Fragment>
            {/* <Panel toggleable={false} collapsed={false} header={''} className={'cart-products ' + props.className}> */}
            <div className="grid">
                {(user?.roleCode === 'ADMIN' || user?.roleCode === 'SYS_ADMIN' || user?.roleCode === 'MANAGER' || user?.roleCode === 'CATMANAGER') && !props.storeId &&
                    <TawreedFormField name="store" className="field col-6" title="lbl_store_name">
                        <StoreMultiSelect name="store" render="standalone" value={search.stores} onChange={e => { setSearch({ ...search, stores: e.value }); setReload(true); }} />
                    </TawreedFormField>
                }
                {!props.priceListId &&
                    <TawreedFormField name="priceScheme" title="lbl_price_scheme_price_list_name" className="field col-6 md:col-6">
                        <TawreedDropdown disabled={props.priceListId} options={priceLists} name="priceScheme"
                            optionLabel="priceListName" optionValue="priceListId" render="standalone" value={search.priceScheme}
                            onChange={e => { setSearch({ ...search, priceScheme: e.value }); setReload(true); }} />
                    </TawreedFormField>
                }
            </div>
            {/* </Panel> */}
            <TawreedTable {...tawreed} />

            <div style={{ display: 'none' }}>
                <Button type="submit"
                    className="w-full"
                    ref={ref}
                    disabled={props.disabled}
                    label={tawreedTranslationService.translate('lbl_add_to_cart')} />
            </div>
            {
                state.isVisible &&
                state.storeProduct &&
                <QuantityDialog
                    customerId={props.customerId}
                    visible={state.isVisible}
                    storeProduct={state.storeProduct}
                    changeQuantity={(quantity: number, bonusType: string) => {
                        props.changeQuantity(quantity, bonusType);
                    }}
                    hide={() => { setState({ storeProduct: undefined, isVisible: false }) }}
                    accept={() => {
                        if (ref.current && ref.current instanceof HTMLButtonElement) {
                            ref.current.click();
                            setState({ storeProduct: undefined, isVisible: false });
                        } else {
                            console.warn('ref.current && ref.current instanceof HTMLButtonElement');
                        }
                    }}
                ></QuantityDialog>}
        </React.Fragment>

    );
}
