import {JObject} from "../../../../common/data/models";
import { FinancePartnerDto, PartnersChartDto, StoreInvoiceDto } from "../../domain/dtos/dashboard";

export const PartnersChartMapper = {

    fromJson(json?: JObject, mutliply?: boolean, partnerName?:boolean): PartnersChartDto {
        if (!json) {
            return {
                color: '#42A5F5',
                value: [],
            };
        }
        return {
            color: '#42A5F5',//'#FFA726'
            total: json.total,
            xlsxReportURI: json.xlsxReportURI,
            pdfReportURI: json.pdfReportURI,

            value: json.financePartnerVoList.map((e:JObject) => {
                    return {
                        name: e.partnerId,
                        label: partnerName ? e.partnerName : e.accountTitle,
                        value: mutliply? -1 * e.balance : e.balance,
                    }
                }),
        };
    },

    fromFinancePartnerJson(json?: JObject): FinancePartnerDto {
        return {
            accountTitle: json?.accountTitle,
            
            accountType: json?.accountType,
            active: json?.active,
            balance: json?.balance,
            balanceId: json?.balanceId,
            partnerId: json?.partnerId,
            partnerName: json?.partnerName,
            partnerType: json?.partnerType
        }
    },

    fromPartnerInvoicesJson(json?: JObject): PartnersChartDto {
        if (!json) {
            return {
                color: '#42A5F5',
                value: [],
            };
        }
        return {
            color: '#42A5F5',//'#FFA726'
            total: json.total,
            xlsxReportURI: json.xlsxReportURI,
            pdfReportURI: json.pdfReportURI,

            value: json.storeInvoicesVOList.map((e:JObject) => {
                    return {
                        name: e.partnerId,
                        label: e.partnerName,
                        value: e.total,
                    }
                }),
        };
    },

    fromStoreInvoiceDto(json?: JObject):StoreInvoiceDto{
        console.log('jsomn',json);
        return {
            partnerId: json?.partnerId,
            partnerName: json?.partnerName,
            total: json?.total
        }   
    }
}
