import React, { useState } from 'react'
import { PrimeIcons } from 'primereact/api';
import { useHistory } from 'react-router-dom';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { Routes } from '../../../../../router';
import { CustomerCartDto, CartService } from '../../domain';
import { CssUtils } from "../../../../../helpers/css";
import { GlobalContext } from "../../../../../context";
import { TawreedTableFormatters } from '../../../../../common/components/table/formatters';
import { Button } from 'primereact/button';
import { tawreedTranslationService } from '../../../../../common/translations';
import { startTawreedDialog } from '../../../../../common/dialog';
import { AuthUtils } from '../../../../auth/domain';
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { Tag } from 'primereact/tag';

export const CartsTable: React.FC = () => {

    const history = useHistory();
    const { auth: { user }, constants: { constants }  } = React.useContext(GlobalContext);
    const service: CartService = React.useMemo(() => new CartService(), []);
    
    const [reload, setReload] = useState(false);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            console.log(user?.roleCode);
            if (user?.roleCode === 'CUSTOMER_ADMIN') { history.push(Routes.SalesCartNew.replace(":id", user!.partnerId!.toString())) }
            else
                history.push(Routes.SalesCartsNew);
            resolve();
        })
    };

    const onForm = React.useCallback(
        (sender?: CustomerCartDto): Promise<void> => {
            return new Promise((resolve) => {
                let route = Routes.SalesCartsDetails.replace(':id', sender!.cartId!.toString());
                if (sender?.priceListId)
                    route += `?priceListId=${sender!.priceListId}`;
                if (sender?.salesOfferId)
                    route += `&&salesOfferId=${sender!.salesOfferId}`;
                route += `&&customerId=${sender!.customerId}`;

                history.push(route);
                resolve();
            });
        },
        [history]);
        const getDependOnRole = () => {
            const arr = [];
            if (user?.roleCode !== 'STORE_ADMIN')
                arr.push({
                    field: "store.partnerName",
                    header: "lbl_store_products_store_name",
                    sortable: true,
                    sortField: 'store',
                    filter: true,
                    filterField: 'store',
                    style: CssUtils.width('20%'),
                })
            return arr;
        }
        const getCartTypeDependOnRole = () => {
            const arr = [];
            if (user?.roleCode === "ADMIN" || user?.roleCode === "SYS_ADMIN" || 
                user?.roleCode === "CATMANAGER" || user?.roleCode === "MANAGER" || user?.roleCode === "SALES")
                arr.push({
                    field: "typeId",
                    header: "lbl_sales_cart_type",
                    filter: true,
                    filterField: 'typeId',
                    sortable: true,
                    ...TawreedTableFilters.dropdown.dropdown('typeId', constants?.cartTypes ?? [], 'value', 'label', 'in', (label: string, value: any) => <Tag rounded severity={value ? "primary" : "warning"} style={{ width: '8rem' }} value={label} />),
                    
                },)
            return arr;
        }
        const onDelete = (id:number): Promise<void> => {
            return new Promise((resolve, reject) => {
                setReload(false);
                return service.deleteCartById(id).then(() => setReload(true)).catch(err => console.error(err));
            })
        };

    const tawreed: TawreedTableProps = {
        header: true,
        reload: reload,
        pagination: true,
        dataKey: 'customer',
        name: 'tbl.sales.carts',
        title: 'lbl_sales_carts',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                dataType: 'text',
                field: "customer.partnerName",
                header: "lbl_sales_cart_customer",
                sortable: true,
                sortField: "customer",
                filter: true,
                filterField: 'customer',
                style: CssUtils.width('20%'),
            },
            ...getDependOnRole(),
            
            {
                field: "priceListName",
                header: "lbl_price_scheme_price_list_name",
                sortable: true,
                sortField: "priceListName",
                filter: true,
                filterField: 'priceListName',
                style: CssUtils.width('15%'),
            },
            // {
            //     field: "salesOfferTitle",
            //     header: "lbl_sales_offer_list_title",
            //     style: CssUtils.width('20%'),
            // },
            {
                field: "total",
                header: "lbl_sales_cart_order_totals",
                filter: true,
                filterField: 'total',
                sortable: true,
                ...TawreedTableFormatters.decimal('total', user?.scale, CssUtils.width('10%')),
            },
            {
                field: "createdAt",
                header: "lbl_sales_cart_created_at",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.date.range('createdAt', 'date'),
                style: CssUtils.width('5%'),
            },
            {
                field: "updatedAt",
                header: "lbl_sales_cart_updated_at",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.date.range('updatedAt', 'date'),
                style: CssUtils.width('5%'),
            },
            ...getCartTypeDependOnRole(),
            {
                field: "actions",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return <React.Fragment>
                        {AuthUtils.current().authorized(TawreedActionName.CartsStoreCartItemDelete) &&
                        <Button
                            type="button"
                            style={{ height: "35px" }}
                            icon={PrimeIcons.TRASH}
                            className="p-button-danger p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_delete')}
                            onClick={() => {
                                return new Promise<void>((resolve, reject) => {
                                startTawreedDialog({
                                    header: tawreedTranslationService.translate('tawreed_dialog_confirm_delete_hdr'),
                                    icon: 'pi pi-exclamation-triangle',
                                    reject: () => reject(),
                                    onHide: () => reject(),
                                    accept: () => onDelete(options!.cartId).then(() => resolve()).catch((err) => reject(err)),
                                });
                                
                            });
                            }} />
                        }
                          { (user?.roleCode === "ADMIN" || user?.roleCode === "SYS_ADMIN" || 
                         user?.roleCode === "CATMANAGER" || user?.roleCode === "MANAGER" 
                        || user?.roleCode === "SALES") && options!.typeId === 2 &&
                        <Button
                            type="button"
                            style={{height: "35px" }}
                            icon={PrimeIcons.EYE}
                            className="p-button-success p-button-outlined"
                            tooltip={tawreedTranslationService.translate('lbl_info')}
                            onClick={() => {
                                let route = Routes.SalesCartsDetails.replace(':id', options!.cartId!.toString());
                                if (options?.priceListId)
                                    route += `?priceListId=${options!.priceListId}`;
                                if (options?.salesOfferId)
                                    route += `&&salesOfferId=${options!.salesOfferId}`;
                                route += `&&customerId=${options!.customerId ? options!.customerId : options.customer.partnerId}`;
                                
                                history.push(route);
                                
                            }} />
                        }
                         { (user?.roleCode === "CUSTOMER_ADMIN" || user?.roleCode === "STORE_ADMIN" || 
                         user?.roleCode === "STORE_SALES_MAN") &&
                        <Button
                            type="button"
                            style={{height: "35px" }}
                            icon={PrimeIcons.EYE}
                            className="p-button-success p-button-outlined"
                            tooltip={tawreedTranslationService.translate('lbl_info')}
                            onClick={() => {
                                let route = Routes.SalesCartsDetails.replace(':id', options!.cartId!.toString());
                                if (options?.priceListId)
                                    route += `?priceListId=${options!.priceListId}`;
                                if (options?.salesOfferId)
                                    route += `&&salesOfferId=${options!.salesOfferId}`;
                                
                                history.push(route);
                                
                            }} />
                        }
                        
                    </React.Fragment>
                },
            }
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.CartsCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
            ],
        },
        row: {
            // onClick: new TawreedAction(TawreedActionName.CartsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm),
        },
        selection: {
            mode: 'single',
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'customer',
                sortOrder: -1,
                multiSortMeta: undefined,
            }
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'store': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'typeId': {
                    operator: 'AND',
                    constraints: [{ 
                        value: (user?.roleCode === "ADMIN" || user?.roleCode === "SYS_ADMIN" || 
                            user?.roleCode === "CATMANAGER" || user?.roleCode === "MANAGER" || user?.roleCode === "SALES") ? [2] : null,
                        matchMode: 'in' }],
                },
                'customer': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'total': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'priceListName':{
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'createdAt': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        }
                    ],
                },
                'updatedAt': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        }
                    ],
                },
            },
        },
    }

    return (<TawreedTable  {...tawreed} />);
}
