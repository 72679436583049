import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { PostDto, PostEtraDto, PostService } from "../../domain"
import { TawreedForm, TawreedFormMode, TawreedFormSubmitProps } from "../../../../common"
import { TawreedAction } from "../../../../common/actions"
import { PostViewComponent } from "./post-view.component"

export const PostView: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const history = useHistory();
    const service = React.useMemo(() => new PostService(), []);


    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<PostDto>({
        extraItems: [],
        titleAr: '',
        titleEn: '',
        descriptionAr: '',
        descriptionEn: '',
        priority: 1,
    });
    const [actions, setActions] = React.useState<TawreedAction[]>([]);

    const bucketURL = process.env.REACT_APP_S3_BUCKET;

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {

                setLoading(true);
                setMode('Edit');
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData({
                });
            }
        }

        return () => {
            mounted = false;
        };
    }, [id, service]);

 const onSubmit: TawreedFormSubmitProps<PostDto> = {
        showButton: false,
        resetOnSubmit: false,
        onSubmit: undefined,
    };

    return (
         <TawreedForm title=""
                    dataKey="" data={data}
                    submit={onSubmit}
                    loading={loading} mode={mode}
                    actions={actions} useBack=".." useReset={false} className="grid form-grid">
        
        <PostViewComponent rowData={data} />
        </TawreedForm>
    )
}
