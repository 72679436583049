import React, { useState } from "react";
import { PrimeIcons } from "primereact/api";
import { useHistory } from "react-router-dom";
import { PostDto, PostService } from "../../domain";
import { GlobalContext } from "../../../../context";
import { Routes } from "../../../../router";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { CssUtils } from "../../../../helpers/css";
import { AuthUtils } from "../../../auth/domain";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { tawreedTranslationService } from "../../../../common/translations";
import { Button } from "primereact/button";


export const PostTable: React.FC = () => {
    const history = useHistory();
    const service = React.useMemo(() => new PostService(), []);
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);
    const [reload, setReload] = useState(false);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.PostsNew);
            resolve();
        });
    }

    const onDetails = (sender?: PostDto): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sender && sender.postId) {
                history.push(Routes.PostsDetails.replace(':id', sender.postId.toString()));
                resolve();
            } else {
                reject();
            }
        });
    }

    // const onActivate = (sender?: DeliveryPartner | Array<DeliveryPartner>): Promise<void> => {
    //     return new Promise((resolve, reject) => {
    //         let partners: DeliveryPartner[] | undefined = undefined;
    //         if (Array.isArray(sender)) {
    //             partners = sender;
    //         } else if (sender) {
    //             partners = [sender];
    //         }
    //         if (!partners || !partners.length) {
    //             reject('No item selected');
    //         } else {
    //             setReload(false);
    //             service.activateAll(partners.map(e=>e.partnerId!))
    //                 .then((result:boolean) => {
    //                     if(result){
    //                         setReload(true);
    //                         resolve();
    //                     }
    //                 })
    //                 .catch(err => {
    //                     reject(err);
    //                 });
    //         }
    //     });
    // };

    // const onDeactivate = (sender?: DeliveryPartner | Array<DeliveryPartner>): Promise<void> => {
    //     return new Promise((resolve, reject) => {
    //         let partners: DeliveryPartner[] | undefined = undefined;
    //         if (Array.isArray(sender)) {
    //             partners = sender;
    //         } else if (sender) {
    //             partners = [sender];
    //         }
    //         if (!partners || !partners.length) {
    //             reject('No item selected');
    //         } else {
    //             setReload(false);
    //             service.deactivateAll(partners.map(e=>e.partnerId!))
    //                 .then(() => {
    //                     setReload(true);
    //                     resolve();
    //                 })
    //                 .catch(err => {
    //                     reject(err);
    //                 });
    //         }
    //     });
    // };
    const onDelete = (sender?: PostDto | Array<PostDto>): Promise<void> => {
        return new Promise((resolve, reject) => {
            let partners: number[];
            if (!sender) {
                reject('No item selected');
                return;
            } else if (Array.isArray(sender)) {
                 setReload(false);
                 partners = sender.map(e => e.postId!);
            } else {
                 partners = [sender.postId!];
                 setReload(false);
            }
            return service.deleteAll(partners).then(() => setReload(true)).catch(err => console.error(err));
        })
    };


    const getStoreColumnDependOnRole = () => {
        const arr = [];
        if (user?.roleCode !== 'STORE_ADMIN')
            arr.push({
                field: "partner.partnerName",
                sortField:" storeName",
                filterField: "storeName",
                header: "lbl_posts_partner_name",
                sortable: true,
                filter: true,
                style: CssUtils.width('23%'),
            })
        return arr;
    }
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'postId',
        name: 'tbl.posts',
        title: 'lbl_posts',
        reload: reload,
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            ...getStoreColumnDependOnRole(),
            {
                field: AuthUtils.current().language === "en" ? "titleEn" : "titleAr",
                header: "lbl_posts_title",
                sortable: true,
                filter: true,
                style: CssUtils.width('23%'),
            },
            // {
            //     field: AuthUtils.current().language === "en" ? "descriptionEn" : "descriptionAr",
            //     header: "lbl_posts_description",
            //     sortable: true,
            //     filter: true,
            //     style: CssUtils.width('23%'),
            // },
            {
                field: "startDate",
                header: "lbl_posts_start_date",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.date.range('startDate', 'date'),
                style: CssUtils.width('20%'),
            },
            {
                field: "endDate",
                header: "lbl_posts_end_date",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.date.range('endDate', 'date'),
                style: CssUtils.width('20%'),
            },
            {
                field: "actions",
                body: (options: any) => {
                    return <React.Fragment>
                        <Button
                            type="button"
                            style={{height: "35px" }}
                            icon={PrimeIcons.PENCIL}
                            className="p-button-success p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_edit')}
                            onClick={() => {
                                history.push(Routes.PostsDetails.replace(':id', options!.postId.toString()));
                                
                            }} />
                              <Button
                            type="button"
                            style={{height: "35px" }}
                            icon={PrimeIcons.EYE}
                            className="p-button-success p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_view')}
                            onClick={() => {
                                history.push(Routes.PostView.replace(':id', options!.postId.toString()));
                            }} />
                    </React.Fragment>
                },
                style: CssUtils.width('10%'),
            }
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.PostCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
                new TawreedAction(TawreedActionName.PostDelete, 'statefull', (e) => tawreedTranslationService.translate('lbl_delete_all', e?.length ?? 0), PrimeIcons.TRASH, onDelete, 'p-button-danger', 'confirmDelete'),
                //new TawreedAction(TawreedActionName.DeliveryPartnersActivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_activate_all', e?.length ?? 0), PrimeIcons.CHECK, onActivate, 'p-button-secondary', 'confirm'),
                //new TawreedAction(TawreedActionName.DeliveryPartnersDeactivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_deactivate_all', e?.length ?? 0), PrimeIcons.CHECK, onDeactivate, 'p-button-secondary', 'confirm'),
            ],
        },
        row: {
            // onClick: new TawreedAction(TawreedActionName.PostDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'storeName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'titleAr': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'titleEn': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'descriptionEn': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'descriptionAr': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'startDate': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        }
                    ],
                },
                'endDate': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        }
                    ],
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'postId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'checkbox',
        },
    }
    return (<TawreedTable  {...tawreed} />);
}