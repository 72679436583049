import { MenuItem } from "primereact/menuitem";
import { Routes } from "../router";

export function getMenu(): Array<MenuItem> {
    return [
        {
            label: 'lbl_home',
            items: [
                {
                    label: "lbl_dashboard",
                    icon: '/assets/images/sidebar/dashboard.svg',
                    to: Routes.Dashboard,
                },
            ],
        },
        {
            label: "lbl_partners",
            items: [
                { label: "lbl_partners_1", icon: '/assets/images/sidebar/stores.svg', to: Routes.PartnersStoresSearch },
                { label: "lbl_partners_2", icon: '/assets/images/sidebar/customers.svg', to: Routes.PartnersCustomersSearch },
                { label: "lbl_connections", icon: '/assets/images/sidebar/stores.svg', to: Routes.PartnerConnectionsSearch },
            ]
        },
        {
            label: "lbl_payments",
            items: [
                { label: "lbl_payment_partners", icon: '/assets/images/sidebar/topuppartners.svg', to: Routes.PaymentPartnersSearch },
                // { label: "lbl_payment_transactions", icon: '/assets/images/sidebar/topuptransactions.svg', to: Routes.PaymentTransactionsSearch },
                { label: "lbl_payment_orders", icon: '/assets/images/sidebar/orders.svg', to: Routes.PaymentOrdersSearch },
                //{ label: "lbl_broken_orders", icon: '/assets/images/sidebar/topuptransactions.svg', to: Routes.BrokenOrdersSearch },
            ]
        },
        {
            label: "lbl_delivery",
            items: [
                { label: "lbl_delivery_partners", icon: '/assets/images/sidebar/topuppartners.svg', to: Routes.DeliveryPartnersSearch },
                // { label: "lbl_payment_transactions", icon: '/assets/images/sidebar/topuptransactions.svg', to: Routes.PaymentTransactionsSearch },
            ]
        },
        {
            label: "lbl_topup",
            items: [
                { label: "lbl_topup_partners", icon: '/assets/images/sidebar/topuppartners.svg', to: Routes.TopUpPartnersSearch },
                { label: "lbl_topup_transactions", icon: '/assets/images/sidebar/topuptransactions.svg', to: Routes.TopUpTransactionsSearch },
                { label: "lbl_topup_wallet_transactions", icon: '/assets/images/sidebar/wallettransactions.svg', to: Routes.WalletTransaction },
            ]
        },
        {
            label: 'lbl_catalog',
            items: [
                { label: "lbl_products", icon: '/assets/images/sidebar/products.svg', to: Routes.ProductsSearch },
                // { label: "lbl_unverified_products", icon: '/assets/images/sidebar/products.svg', to: Routes.UnverifiedProductsSearch },
                { label: "lbl_store_products", icon: '/assets/images/sidebar/storesproducts.svg', to: Routes.StoreProductsSearch },
                // { label: "lbl_sales_offer_list_title", icon: '/assets/images/sidebar/products.svg', to: Routes.SalesOfferSearch },
                { label: "lbl_import_helper", icon: '/assets/images/sidebar/storesproducts.svg', to: Routes.ImportHelper },
                { label: "lbl_import_store_product", icon: '/assets/images/sidebar/storesproducts.svg', to: Routes.ImportStoreProductsSearch },
                // {label: "lbl_products_upload", icon: '/assets/images/sidebar/', to: Routes.StoreProductsStageSearch},
            ]
        },
        {
            label: 'lbl_sales',
            items: [
                { label: "lbl_sales_carts", icon: '/assets/images/sidebar/shoppingcarts.svg', to: Routes.SalesCartsSearch },
                { label: "lbl_sales_stores_placed_orders", icon: '/assets/images/sidebar/ordersplaced.svg', to: Routes.SalesOrdersPlacedSearch },
                { label: "lbl_sales_stores_processing_orders", icon: '/assets/images/sidebar/ordersprocessing.svg', to: Routes.SalesOrdersProcessingSearch },
                { label: "lbl_sales_stores_ready_orders", icon: '/assets/images/sidebar/ordersready.svg', to: Routes.SalesOrdersReadySearch },
                { label: "lbl_sales_customers_shipped_orders", icon: '/assets/images/sidebar/ordersshipped.svg', to: Routes.SalesOrdersShippedSearch },
                { label: "lbl_sales_orders", icon: '/assets/images/sidebar/orders.svg', to: Routes.SalesOrdersSearch },
            ]
        },
        // {
        //     label: "lbl_sales_returned_orders",
        //     items: [
        //         { label: "lbl_sales_returned_orders_new", icon: '/assets/images/sidebar/', to: Routes.SalesOrdersReturnedNew },
        //         { label: "lbl_sales_returned_orders_shipped", icon: '/assets/images/sidebar/', to: Routes.SalesOrdersReturnedShippedSearch },
        //         { label: "lbl_sales_returned_orders", icon: '/assets/images/sidebar/', to: Routes.SalesOrdersReturnedSearch },
        //     ]
        // },
        {
            label: 'lbl_finance_documents',
            items: [
                { label: 'lbl_finance_documents_1', icon: '/assets/images/sidebar/financepaymentvoucher.svg', to: Routes.FinanceDocumentType1 },
                { label: 'lbl_finance_documents_2', icon: '/assets/images/sidebar/financereceiptvoucher.svg', to: Routes.FinanceDocumentType2 },
                { label: 'lbl_finance_documents_3', icon: '/assets/images/sidebar/financecreditnote.svg', to: Routes.FinanceDocumentType3 },
                { label: 'lbl_finance_documents_4', icon: '/assets/images/sidebar/financedebitnote.svg', to: Routes.FinanceDocumentType4 },
                { label: 'lbl_finance_documents_journal', icon: '/assets/images/sidebar/financedebitnote.svg', to: Routes.FinanceDocumentJournalType },
            ]
        },
        
        {
            label: 'lbl_reports',
            items: [
                {
                    label: 'lbl_reports_financial',
                    icon: '/assets/images/sidebar/reportsfinance.svg',
                    items: [
                        {label: 'lbl_dashboard', icon: '/assets/images/sidebar/dashboard.svg', to: Routes.ReportsFinancialDashboard},
                        { label: 'lbl_reports_financial_documents', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsFinancialDocuments },
                        { label: 'lbl_reports_financial_statements_per_partner', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsFinancialStatementsPerPartner },
                        { label: 'lbl_reports_financial_statements_per_account', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsFinancialStatementsPerAccount },
                        { label: 'lbl_reports_invoices', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsInvoices },
                        { label: 'lbl_reports_invoices_returned', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsReturnedInvoices },
                        { label: 'lbl_reports_trial_balance', icon: '/assets/images/sidebar/reportsfinance.svg', to: Routes.ReportsTrialBalance },
                    ]
                },
                {
                    label: 'lbl_reports_sales',
                    icon: '/assets/images/sidebar/reportssales.svg',
                    items: [
                        { label: 'lbl_reports_sales_orders', icon: '/assets/images/sidebar/reportssales.svg', to: Routes.ReportsSalesOrders },
                        { label: 'lbl_reports_sales_returned_orders', icon: '/assets/images/sidebar/reportssales.svg', to: Routes.ReportsSalesReturnedOrders },
                    ]
                },
                
                {
                    label: "lbl_payment_report",
                    icon:'/assets/images/sidebar/topuppartners.svg',
                    items: [
                        { label: "lbl_payment_delivered", icon: '/assets/images/sidebar/orders.svg', to: Routes.DeliveredOrdersSearch },
                        { label: "lbl_payment_shipped", icon: '/assets/images/sidebar/ordersshipped.svg', to: Routes.ShippedOrdersSearch },
                        { label: "lbl_payment_placed", icon: '/assets/images/sidebar/ordersplaced.svg', to: Routes.PlacedOrdersSearch },
                        // { label: "lbl_broken_orders", icon: '/assets/images/sidebar/topuptransactions.svg', to: Routes.BrokenOrdersSearch },
                    ]
                }
            ]
        },
        {
            label: "lbl_marketing",
            items: [
                { label: "lbl_posts", icon: '/assets/images/sidebar/ordersplaced.svg', to: Routes.PostsSearch },
                { label: "lbl_marketing_campaign", icon: '/assets/images/sidebar/marketingmessages.svg', to: Routes.MarketingNotificationCampaign },
                { label: "lbl_banner", icon: '/assets/images/sidebar/banners.svg', to: Routes.SettingsBanner },
            ]
        },
        {
            label: "lbl_management",
            items: [
                { label: "lbl_users", icon: '/assets/images/sidebar/managementusers.svg', to: Routes.UsersSearch },
                { label: "lbl_zones", icon: '/assets/images/sidebar/managementzones.svg', to: Routes.ZonesSearch },
                { label: "lbl_store_zones", icon: '/assets/images/sidebar/stores-zones.svg', to: Routes.StoreZonesSearch },
                { label: "lbl_category", icon: '/assets/images/sidebar/categories.svg', to: Routes.SettingsCategory },
            ]
        },
    ];
}
