import React from "react";
import { Timeline } from "primereact/timeline";
import { Panel } from "primereact/panel";
import { tawreedTranslationService } from "../../../../../../common/translations";
import { HistoryItem, HistoryTrackingItem } from "../../../data";
import { DateUtils } from "../../../../../../helpers/date";
import { GlobalContext } from "../../../../../../context";
import { TawreedInputCustom } from "../../../../../../common/components/form";
import { TawreedMenuItem } from "../../../../../constants";
import { AuthUtils } from "../../../../../auth/domain";

export type OrderFormHistoryProps = {
    className: string;
    canceled?: boolean
};

export const OrderFormHistory: React.FC<OrderFormHistoryProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);
    const lang = AuthUtils.current().language;
    
    return (
        <Panel header={tawreedTranslationService.translate(`lbl_sales_orders_o_history`)} className={props.className} style={{ height: '60vh !important', maxHeight: '60vh !important' }}>
            <div className="grid" style={{ height: '60vh !important', maxHeight: '60vh !important' }}>
                <div className="col-12" style={{ height: '60vh !important', maxHeight: '60vh !important' }}>
                    <TawreedInputCustom
                        render="form"
                        name="historyList"
                        renderer={(value: HistoryTrackingItem[]) => {
                            return <Timeline
                                value={value}
                                layout="vertical"
                                opposite={(item) => {

                                    // if (item && item.statusId && constants && constants.orderStatuses) {
                                    //     const e = constants.orderStatuses.find(e => e.value === item.statusId);
                                    //     if (e) {
                                    //         return e.label;
                                    //     }
                                    // }
                                    // return '';
                                    return item.message;
                                }}
                                content={(item) => <small className="p-text-secondary">{DateUtils.format(item.createdAt, 'datetime')}</small>} />
                        }} />
                        {
                            props.canceled &&
                            <TawreedInputCustom
                        render="form"
                        name= {lang === "en" ? "reasonEn": "reasonAr"}
                        renderer={(value: any) => {
                            console.log("value", lang);
                            return (
                                <div className="mt-2">
                                    <label htmlFor="cancel_reason_id" className="font-bold">{tawreedTranslationService.translate('lbl_sales_orders_o_cancel_reason')}</label>
                                    <p id="cancel_reason_id">
                                        {
                                            value
                                        }
                                    </p>
                                </div>
                            )
                        }} />
                        }
                    {/* <TawreedInputCustom
                        render="form"
                        name="cancelReason"
                        renderer={(value: TawreedMenuItem) => {
                            console.log("value",value);
                            if (!value) {
                                return <React.Fragment></React.Fragment>
                            }
                            return (
                                <div className="mt-2">
                                    <label htmlFor="cancel_reason_id" className="font-bold">{tawreedTranslationService.translate('lbl_sales_orders_o_cancel_reason')}</label>
                                    <p id="cancel_reason_id">
                                        {
                                            value.label
                                        }
                                    </p>
                                </div>
                            )
                        }} /> */}

                </div>
            </div>
        </Panel>
    );
}
