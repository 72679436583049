import { Button } from "primereact/button";
import React, { FC } from 'react';
import { tawreedTranslationService } from "../../../../common/translations";
import { GlobalContext } from "../../../../context";
import { AuthUtils } from "../../../auth/domain";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { Dialog } from "primereact/dialog";
import { CssUtils } from "../../../../helpers/css";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { DashboardService } from "../../domain";

export interface StoreOrdersDialogProps {
    hide: () => void;
    xlsxReportURI?: string;
    // pdfReportURI: string;
    visible: boolean;
    data?: any[]
}

export const StoreOrdersDialog: FC<StoreOrdersDialogProps> = ({ data, visible, hide,xlsxReportURI}) => {

    const service = React.useMemo(() => new DashboardService(), []);
    const print = (report: string) => {
        return service.print(report)
            .then(() => {
            })
            .catch(err => console.error(err));
    };

    const FinancePartnerDialogFooter = () => {
        return  <div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />

        </div>;
    }
    const FinancePartnerDialogHeader= () => {
        return <div>
        <p>{tawreedTranslationService.translate("lbl_dashboard_partners_chart_title")}</p>
    </div>
    }
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);

    const lang = AuthUtils.current().language;

    const tawreed: TawreedTableProps = {
        header: false,
        disableGlobalSearch: true,
        pagination: true,
        dataKey: 'balanceId',
        name: '',
        title: '',
        ds: {
            mode: 'basic',
            data: data ? data : [],
        },
        columns: [

            {
                field: "label",
                header: "lbl_partner_1",
                sortable: true,
                filter: false,
                style: CssUtils.width('30%')
            },
            {
                field: "value",
                header: "lbl_sales_orders",
                sortable: true,
                filter: false,
                alignHeader:lang === "en" ? 'right' : 'left' ,
                ...TawreedTableFormatters.decimal('value', user?.scale! ,undefined, CssUtils.width('25%')),
            }

        ],
        toolbar: {
            actions: data && data?.length > 0 ? [

                new TawreedAction(TawreedActionName.ReportsExport, 'stateless', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => print(xlsxReportURI!)),
                //new TawreedAction(TawreedActionName.ReportsExport, 'stateless', 'lbl_reports_export_as_pdf', PrimeIcons.FILE_PDF, () => print(pdfReportURI))
            ] : [],
        },
        filter: {
            filterDisplay: 'menu',
            initial: {}
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'balance',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },

    }

    return (
        
        <Dialog maximizable resizable style={{ minWidth: '80%' }} 
            header={<FinancePartnerDialogHeader />} 
            visible={visible} footer={<FinancePartnerDialogFooter />} onHide={hide} draggable={false}
             position="top" closable={true} closeOnEscape={true}>
            <TawreedTable  {...tawreed} />
        </Dialog>
    )
}
