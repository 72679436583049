import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { PostsRepository } from "../../data";
import { PostDto } from "../dtos";

export class PostService {
    private readonly repository;

    constructor() {
        this.repository = new PostsRepository();
    }

    public search(req: PaginatedRequestParams): Promise<PaginatedResponse<PostDto>> {
        return this.repository.search(req);
    }
    public searchAutoComplete(req: string, lang: string): Promise<PaginatedResponse<PostDto>> {
        return this.repository.searchAutoComplete(req, lang);
    }

    public createOrUpdate(form: PostDto): Promise<PostDto> {
        console.log("da",form);
        return this.repository.createOrUpdate(form);
    }

    public getDetails(id: number): Promise<PostDto>{
        return this.repository.getDetails(id);
    }

    public deleteAll(ids: number[]) : Promise<Boolean> {
        return this.repository.deleteAll(ids);
    }
} 