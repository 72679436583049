import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from "react-hook-form";

import { PrimeIcons } from 'primereact/api';
import { SplitButton } from "primereact/splitbutton";
import { Toolbar } from 'primereact/toolbar';

import { computeTawreedActionTitle, TawreedAction, TawreedActionButton, TawreedActionName } from '../../actions';
import { FormError } from '../../errors';
import { tawreedTranslationService } from '../../translations';
import { MenuItem } from 'primereact/menuitem';
import { AuthUtils } from "../../../modules/auth/domain";
import { TawreedFormSubmitProps } from "./actions";
import { Button } from "primereact/button";

import './form.scss';

export type TawreedFormMode = 'Create' | 'Edit';

/**
 * Form Props
 */
export type TawreedFormProps<T> = {

    /**
     * mode
     */
    mode: TawreedFormMode;

    /**
     * title
     */
    title: string;

    /**
     * key
     */
    dataKey: any;

    /**
     * current form data
     */
    data: T;

    /**
     * submit props
     */
    submit: TawreedFormSubmitProps<T>;

    /**
     * Loading?
     */
    loading?: boolean;

    /**
     * actions
     */
    actions?: Array<TawreedAction<T>>;

    /**
     * show reset button?
     */
    useReset?: boolean;

    /**
     * show back button?
     */
    useBack?: TawreedAction<T> | '..' | undefined;

    /**
     * additional classes
     */
    className?: string;

    children: ReactNode; // Make sure to include children

    customeTitle?: string;
    style?:any;
    onRest?: () => void;
}

export const TawreedForm = forwardRef<any | undefined, TawreedFormProps<any>>((props, ref) => {

    const methods = useForm();
    const history = useHistory();
    const formRef = React.useRef<HTMLFormElement>(null);

    useImperativeHandle(ref, () => {
        return {

            submit() {
                if (formRef && formRef.current) {
                    formRef.current.requestSubmit();
                }
            },
        };
    }, [formRef]);

    const refresh = React.useCallback((data: any) => {

        // function fill(prefix: string, field: Field, value: any) {
        //     if (field._f) {
        //         methods.setValue(prefix, value);
        //         return;
        //     }
        //     Object.keys(field).forEach(key => fill(prefix + '.' + key, (field as { [key: string]: any })[key], value ? value[key] : undefined));
        // }

        if (!Object.is(data, methods.getValues())) {
            // Object.keys(methods.control._fields)
            //     .forEach(key => {
            //         if (methods.control._fields[key]) {
            //             fill(key, methods.control._fields[key]!, data[key]);
            //         }
            //     });
            Object.keys(data).forEach(k => {
                methods.setValue(k, data[k]);
            })
        }
        if (props.dataKey && data[props.dataKey]) {
            methods.setValue(props.dataKey, data[props.dataKey]);
        }
    }, [methods, props.dataKey]);

    React.useEffect(
        () => {
            if (props.data) {
                refresh(props.data);
            }
        }, [props.data, refresh]);

    const onSubmit = (data: any) => {
        if (props.submit.onSubmit && AuthUtils.current().authorized(props.submit.onSubmit.name)) {
            const callback = (res: any) => {
                // IF failed then setErrors
                // ELSE refresh values or reset based on properties
                if (res instanceof Error) {
                    if (res instanceof FormError) {
                        for (const e of res.fields) {
                            methods.setError(e.field, { message: e.message });
                        }
                        return;
                    } else if (res.message) {
                        console.error('FORM_ERROR', res.message);
                    }
                } else {
                    if (props.submit.resetOnSubmit) {
                        methods.reset(props.data);
                    } else if (res) {
                        refresh(res);
                    }
                }

            };
            props.submit.onSubmit.execute(data, callback).then();
        }
    };

    const onBack = (): Promise<void> => {
        return new Promise((resolve) => {
            if (props.useBack) {
                if (props.useBack === '..') {
                    history.goBack();
                    return;
                }
                props.useBack.execute().then();
            }
            resolve();
        })
    }

    const onReset = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (props.useReset && props.data) {
                methods.reset(props.data);
                if(props.onRest)
                {
                    props.onRest();
                }
                return resolve();
            }
            return reject();
        });
    }

    const onSave = (): Promise<void> => {
        return new Promise((resolve) => {
            if (formRef && formRef.current) {
                formRef.current.requestSubmit();
            }
            return resolve();
        });
    }

    const getComputedTawreedActionTitle = (e: TawreedAction | string): string => {
        if (e instanceof TawreedAction) {
            return tawreedTranslationService.translate(computeTawreedActionTitle({ snapshot: methods.control._formValues }, e.title));
        }
        return tawreedTranslationService.translate(e);
    }

    const TawreedFormToolbarSplitButton: React.FC<{ useBack: TawreedAction | undefined, actions: Array<TawreedAction> }> = ({ actions, useBack }) => {
        const items: MenuItem[] = [];
        
        for (let i = 1; i < actions.length; i++) {
            items.push({
                label: getComputedTawreedActionTitle(actions[i]),
                icon: actions[i].icon,
                command: () => actions[i].execute().then(),
                disabled: props.loading || (actions[i].name === TawreedActionName.Reset && !methods.formState.isDirty),
            });
        }

        if (!actions || !actions.length) {
            if (useBack) {
                return <Button label={getComputedTawreedActionTitle(useBack)} icon={useBack.icon} className={useBack.className} onClick={() => useBack.execute().then()} disabled={props.loading} />
            }
            return <React.Fragment />
        }
        return (
            <React.Fragment>
                {
                    useBack &&
                    <Button type="button" label={getComputedTawreedActionTitle(useBack)} icon={useBack.icon} className={'p-button-sm ' + useBack.className} onClick={() => useBack.execute().then()} disabled={props.loading} />
                }
                <SplitButton className="p-button-sm" label={getComputedTawreedActionTitle(actions[0])} icon={actions[0].icon} onClick={() => actions[0].execute().then()} disabled={props.loading} model={items} />
            </React.Fragment>
        )
    }

    const TawreedFormToolbar: React.FC = () => {

        const actions = new Array<TawreedAction<any, any>>();

        if (props.submit && props.submit.showButton && AuthUtils.current().authorized(props.submit.onSubmit.name)) {
            actions.push(new TawreedAction<any, any>(props.submit.onSubmit.name, props.submit.onSubmit.type, props.submit.onSubmit.title, props.submit.onSubmit.icon, onSave, props.submit.onSubmit.className, props.submit.onSubmit.prompt));
        }

        if (props.actions && props.actions.length) {
            actions.push(...props.actions.filter(e => {
                if (!AuthUtils.current().authorized(e.name)) {
                    return false;
                }
                return e.type === 'stateless' || (e.type === 'statefull' && props.dataKey && props.data[props.dataKey]);
            }));
        }

        if (props.submit && props.submit.showButton && props.useReset) {
            actions.push(new TawreedAction<any, any>(TawreedActionName.Reset, 'stateless', 'lbl_reset', PrimeIcons.REPLAY, onReset, 'p-button-secondary'))
        }

        let back: TawreedAction<any, any> | undefined = undefined;
        if (props.useBack) {
            if (props.useBack === '..') {
                back = new TawreedAction<any, any>(TawreedActionName.Back, 'stateless', 'lbl_back', PrimeIcons.CHEVRON_LEFT, onBack, 'p-button-secondary');
            } else {
                back = props.useBack;
            }
        }

        if ((!actions || !actions.length) && !back) {
            return <React.Fragment />;
        }

        return (
            <Toolbar className="form-tawreed-toolbar"
                left={
                    <React.Fragment>
                        <div className="flex md:hidden">
                            <TawreedFormToolbarSplitButton useBack={back} actions={actions} />
                        </div>
                        <div className="hidden md:flex">
                            {
                                back &&
                                <Button type="button" label={getComputedTawreedActionTitle(back)} icon={back.icon} className={back.className} onClick={() => back && back.execute().then()} disabled={props.loading} />
                            }
                            {
                                actions.map(((e, index) => <TawreedActionButton key={index} type="button" action={e} disabled={props.loading || (e.name === TawreedActionName.Reset && !methods.formState.isDirty)} />))
                            }
                        </div>
                    </React.Fragment>
                } />
        )
    }

    return (
        <div className="form-tawreed" style={{...props.style}}>
            {!props.customeTitle && <h4>{tawreedTranslationService.translate(props.title)} {props.data ? props.data[props.dataKey] : ''}</h4>}
            {props.customeTitle && <h4>{tawreedTranslationService.translate(props.title)} {props.customeTitle}</h4>}

            <TawreedFormToolbar />

            <FormProvider {...methods} >
                <form autoComplete="off" ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={"form-tawreed-fields " + props.className}>
                        {
                            props.loading &&
                            <div className="form-tawreed-loader p-component-overlay">
                                <i className="form-tawreed-loader-icon pi-spin pi pi-spinner" />
                            </div>
                        }
                        {props.children}
                        {/* <button type="submit" className="hidden"/> */}
                    </div>
                </form>
            </FormProvider>
        </div>
    );
})
