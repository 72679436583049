import React from "react";
import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import { TawreedActionName } from "../../../../common/actions";
import { TawreedForm, TawreedFormField, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputText, TawreedInputTextarea } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { MarketingService, NotificationCampaign } from "../../domain";
import { PartnerTypeDropdown } from "../../../partners/presentaion/components";
import { TabPanel, TabView } from "primereact/tabview";
import { NotificationCampaignMessage } from "./notification-compaing-message";
import { NotificationCampaignPost } from "./notification-compaing-post";
// import { ZonesTableRelation } from "../../../zones/presentation/components/zones.table-relation";


export const NotificationCampaignForm: React.FC = (_props) => {
    // di
    const service: MarketingService = React.useMemo(() => new MarketingService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    
    const [activeIndex, setActiveIndex] = React.useState(0);

    const onCreateOrSave = (data: NotificationCampaign, cb: TawreedFormSubmitCallback<NotificationCampaign>): Promise<void> => {
        setLoading(true);
        return service.campaign({ ...data })
            .then(res => {
                setLoading(false);
                cb(data);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<NotificationCampaign> = {
        showButton: true,
        resetOnSubmit: true,
        onSubmit: new TawreedFormSubmitAction<NotificationCampaign>(TawreedActionName.MarketingCampaign, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };

    return (
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="col-12">
            <TabPanel header={tawreedTranslationService.translate('lbl_marketing_campaign')} >
                <NotificationCampaignMessage />
            </TabPanel>
            <TabPanel header={tawreedTranslationService.translate('lbl_posts')} >
                {/* <CarFormSalesOffer actor={cart.customer} zoneId={cart.customer.zoneId} onSelect={onPackageSelect} disabled={loading || !form || !form.customer} className="col-12" /> */}
                <NotificationCampaignPost />
            </TabPanel>
        </TabView>
    );
}
