import React from "react";
import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import { TawreedActionName } from "../../../../common/actions";
import { TawreedForm, TawreedFormField, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputText, TawreedInputTextarea } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { MarketingService, NotificationCampaign } from "../../domain";
import { PartnerTypeDropdown } from "../../../partners/presentaion/components";
import { PostAutoCompleteComponent, PostViewComponent } from "../../../posts";
import { AuthUtils } from "../../../auth/domain";
import { PostDto } from "../../../posts/domain";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../router";


export const NotificationCampaignPost: React.FC = (_props) => {
    // di
    const service: MarketingService = React.useMemo(() => new MarketingService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [post, setPost] = React.useState<PostDto | undefined>(undefined);
    
    const history = useHistory();

    const onCreateOrSave = (data: NotificationCampaign, cb: TawreedFormSubmitCallback<NotificationCampaign>): Promise<void> => {
        setLoading(true);
        return service.campaign({ ...data, postId: post?.postId! })
            .then(res => {
                setLoading(false);
                setPost(undefined);
                cb(data);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<NotificationCampaign> = {
        showButton: true,
        resetOnSubmit: true,
        onSubmit: new TawreedFormSubmitAction<NotificationCampaign>(TawreedActionName.MarketingCampaign, 'lbl_send', PrimeIcons.SAVE, onCreateOrSave),
    };

    return (
        <TawreedForm title={''} dataKey="userId" data={{}}
            submit={onSubmit} onRest={()=>{setPost(undefined)}}
            loading={loading} mode="Create" useReset={true} className="grid form-grid">

            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="partnerType" className="field col-12" title="lbl_marketing_campaign_partner_type">
                        <PartnerTypeDropdown name="partnerType" render="form" placeholder={tawreedTranslationService.translate('lbl_partners_all')} />
                    </TawreedFormField>
                    <TawreedFormField name="postId"
                    hintElement={<Button
                                                type="button"
                                                style={{height: "35px" }}
                                                className="p-button-success p-button-text"
                                                label={tawreedTranslationService.translate('lbl_new')}
                                                onClick={() => {
                                                    history.push(Routes.PostsNew);
                                                    
                                                }} />}
                    className="field col-12" title="lbl_posts">
                        <PostAutoCompleteComponent
                            autoCompleteName="postId"
                            autoCompleteRules={{ required: 'msg_field_is_required' }}
                            autoCompleteField={AuthUtils.current().language === "en" ? "titleEn" : "titleAr"}
                            autoCompleteOnSelect={e => {
                                setPost(e.value);
                            }}
                            autoCompleteRender="form" />
                    </TawreedFormField>
                    



                </div>
            </Panel>
            {
                post && <PostViewComponent rowData={post} />
            }
        </TawreedForm>
    );
}
