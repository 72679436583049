import React from "react";
import { Tag } from "primereact/tag";
import { TawreedMenu } from "../../../constants";
import { PostDto } from "../../domain";
import { AuthUtils } from "../../../auth/domain";
import { DateUtils } from "../../../../helpers/date";


export type PostAutocompleteItemTemplateProps = {
    /**
     *
     */
    rowData: PostDto;
}

export const PostAutocompleteItemTemplate: React.FC<PostAutocompleteItemTemplateProps> = (props) => {

    return (
        <div className="">
            <div className="flex">
                <span className="mr-auto">{AuthUtils.current().language === "en" ?
                    props.rowData.titleEn : props.rowData.titleAr}</span>
                <Tag rounded severity={'success'}
                    value={props.rowData.partner?.partnerName} />
            </div>
            <div style={{ fontSize: '.75rem', color: 'var(--text-secondary-color)' }}>
                {DateUtils.format(props.rowData.startDate!, "date")}
                <span> - </span>
                {DateUtils.format(props.rowData.endDate!, "date")}
            </div>
        </div>
    );
}
