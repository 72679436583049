import { JObject } from "../../../../common/data/models";
import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { OrderReportParamsMapper, ReportMapper } from "../mappers";
import { ReportRepository } from "./report.repository";
import { OrderReportItem, OrderReportParams, ReportDto, ReportResultDto } from "../../domain";
import { PaginatedRequestParams } from "../../../../common/pagination";
import { TawreedConstants } from "../../../constants";
import { OrderReportMapper } from "../mappers/orders-report";
import { TawreedAction } from "../../../../common/actions";
import { CssUtils } from "../../../../helpers/css";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { TawreedRoleName } from "../../../auth/domain";
import { PartnerType } from "../../../partners/data";

export class OrdersReportRepository extends ReportRepository<OrderReportParams, OrderReportItem> {

    /**
     *
     * @param returned
     * @param params
     */
    public generate(params: OrderReportParams, scale: number, roleCode: TawreedRoleName , partnerType: PartnerType | undefined, constants: TawreedConstants | undefined, returned?: boolean): Promise<ReportDto<OrderReportItem>> {
        if (returned) {
            return Promise.reject(false);
        }
        const dataKey = 'orderId';
        const columns = [
            { field: "orderId", header: 'lbl_sales_orders_order_id', style: CssUtils.width('10%') },
            { field: "orderDate", header: 'lbl_sales_orders_date', ...TawreedTableFilters.date.range('orderDate', 'date'), style: CssUtils.width('10%') },
            { field: "store.partnerName", header: 'lbl_sales_orders_store', style: CssUtils.width('25%') },
            { field: "customer.partnerName", header: 'lbl_sales_orders_customer', style: CssUtils.width('25%') },
            { field: "itemsQuantity", header: 'lbl_sales_orders_items_count', style: CssUtils.width('10%') },
            { field: "totalTax", header: 'lbl_sales_orders_total_tax', ...TawreedTableFormatters.decimal('totalTax', scale, CssUtils.width('10%')), className: CssUtils.className.field.amount },
            { field: "total", header: 'lbl_sales_orders_total', ...TawreedTableFormatters.decimal('total', scale, CssUtils.width('10%')), className: CssUtils.className.field.amount },
        ];
        const actions = (rowData: OrderReportItem) => {
            const res = new Array<TawreedAction>();
            // if (rowData.showPrint) {
            // }
            return res;
        };
        const filter = OrderReportParamsMapper.toFilter(params);
        const onSearch = (req: PaginatedRequestParams) => this.search(req, scale, undefined);
        return Promise.resolve(ReportMapper.toDto({ ...params, dataKey, columns, filter, onSearch, actions }));
    }


    public search(request: PaginatedRequestParams, scale: number, constants: undefined): Promise<ReportResultDto<OrderReportItem>> {
        const params = {
            sort: 'orderId,desc',
            page: request.page,
            size: request.size,
        };
        const data = this.fromRequestParamsToJObject(request);

        return tawreedHttpService.post<JObject, JObject>(endpoints.REPORTS_SALES_ORDERS_FETCH, data, params, undefined, 'error')
            .then((res) => {
                if (!res || !res.data) {
                    return Promise.reject(null);
                }
                return OrderReportMapper.fromJson(res.data);
            });
    }
}