import React from "react";
import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import { TawreedActionName } from "../../../../common/actions";
import { TawreedForm, TawreedFormField, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputText, TawreedInputTextarea } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { MarketingService, NotificationCampaign } from "../../domain";
import { PartnerTypeDropdown } from "../../../partners/presentaion/components";
// import { ZonesTableRelation } from "../../../zones/presentation/components/zones.table-relation";


export const NotificationCampaignMessage: React.FC = (_props) => {
    // di
    const service: MarketingService = React.useMemo(() => new MarketingService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);

    const onCreateOrSave = (data: NotificationCampaign, cb: TawreedFormSubmitCallback<NotificationCampaign>): Promise<void> => {
        setLoading(true);
        return service.campaign({ ...data })
            .then(res => {
                setLoading(false);
                cb(data);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<NotificationCampaign> = {
        showButton: true,
        resetOnSubmit: true,
        onSubmit: new TawreedFormSubmitAction<NotificationCampaign>(TawreedActionName.MarketingCampaign, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };

    return (
        <TawreedForm title={''} dataKey="userId" data={{}}
            submit={onSubmit}
            loading={loading} mode="Create" useReset={true} className="grid form-grid">

            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="partnerType" className="field col-12" title="lbl_marketing_campaign_partner_type">
                        <PartnerTypeDropdown name="partnerType" render="form" placeholder={tawreedTranslationService.translate('lbl_partners_all')}/>
                    </TawreedFormField>

                    <TawreedFormField name="title" className="field col-12" title="lbl_marketing_campaign_title">
                        <TawreedInputText name="title" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="message" className="field col-12" title="lbl_marketing_campaign_message">
                        <TawreedInputTextarea name="message" render="form" rules={{ required: 'msg_field_is_required' }} rows={3} />
                    </TawreedFormField>


                    {/* <ZonesTableRelation name="zones" render="form" rules={{ required: 'msg_field_is_required' }} title="lbl_marketing_campaign_zones"/> */}
                </div>
            </Panel>
        </TawreedForm>
    );
}
