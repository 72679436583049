import { tawreedHttpService } from "../../../../http";
import { buildFilterMetaToObject, PaginatedRequestCriteria, PaginatedRequestParams } from "../../../../common/pagination";
import { ReportDto, ReportResultDto } from "../../domain";
import { TawreedConstants } from "../../../constants";
import { PartnerType } from "../../../partners/data";


export const UNSUPPORTED_RENDER_MODE = 'UNSUPPORTED_RENDER_MODE';

export abstract class ReportRepository<TParams, TItem> {

    /**
     * 
     * @param req 
     * @returns 
     */
    protected fromRequestParamsToJObject(req: PaginatedRequestParams) {

        const ee = buildFilterMetaToObject(req.keyword, req.filters);
        if (!ee || !ee.criteria) {
            return {};
        }
        const ans1: PaginatedRequestCriteria[] = ee.criteria;
        const ans2: any = {};
        ans1.forEach(e => {
            //
            if (ans2[e.fieldName]) {
                console.warn('overrwite', e);
            }
            ans2[e.fieldName] = e.value;
        });

        return ans2;
    }

    /**
     * 
     * @param url 
     */
    public export(url: string): void {
        window.open(tawreedHttpService.baseURL + url);
    }

    /**
     * 
     * @param render 
     * @param params 
     */
    public abstract generate(params: TParams, scale: number, roleCode: string , partnerType: PartnerType|undefined ,constants: TawreedConstants | undefined): Promise<ReportDto<TItem>>;

    /**
     * 
     * @param request 
     */
    public abstract search(request: PaginatedRequestParams, scale: number, constants: TawreedConstants | undefined): Promise<ReportResultDto<TItem>>;
}
